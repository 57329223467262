import React, { useState, useEffect, useCallback, useRef } from 'react';
import { motion, useScroll, useTransform, AnimatePresence } from 'framer-motion';
import { 
  ChevronRight, Globe, Users, Lightbulb, Calendar, MapPin, 
  Award, ArrowUpRight, Sparkles, Star, Heart, HandHeart
} from 'lucide-react';

// Optimized GridOverlay with reduced opacity for better contrast
const GridOverlay = React.memo(() => (
  <div className="absolute inset-0 pointer-events-none">
    <div
      className="h-full w-full"
      style={{
        backgroundImage: `linear-gradient(to right, rgba(139, 92, 246, 0.15) 1px, transparent 1px),
                          linear-gradient(to bottom, rgba(139, 92, 246, 0.15) 1px, transparent 1px)`,
        backgroundSize: '50px 50px',
        animation: 'gridScroll 15s linear infinite',
        opacity: 0.8
      }}
    />
  </div>
));

const CategoryCard = ({ title, description, icon: Icon, index }) => (
  <motion.div
    initial={{ opacity: 0, scale: 0.9 }}
    whileInView={{ opacity: 1, scale: 1 }}
    viewport={{ once: true }}
    transition={{ duration: 0.5, delay: 0.2 * index }}
    className="group relative overflow-hidden rounded-2xl bg-gradient-to-br from-surface-100 to-surface-200 backdrop-blur-lg hover:shadow-2xl hover:shadow-purple-500/10 transition-all duration-500"
  >
    <div className="absolute inset-0 bg-gradient-to-br from-purple-500/20 to-purple-600/20 opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
    <motion.div
      whileHover={{ y: -5 }}
      className="relative p-8"
    >
      <motion.div
        whileHover={{ rotate: 180 }}
        transition={{ duration: 0.5 }}
        className="mb-4 inline-block p-3 rounded-xl bg-purple-500/10 group-hover:bg-purple-500/20 transition-colors duration-300"
      >
        <Icon className="w-6 h-6 text-purple-400" />
      </motion.div>
      <h3 className="text-xl font-semibold text-mono-50 mb-2">{title}</h3>
      <p className="text-mono-200 group-hover:text-mono-100 transition-colors duration-300 mb-4">
        {description}
      </p>
     
    </motion.div>
  </motion.div>
);

// Enhanced Spotlight with smoother gradient
const Spotlight = React.memo(() => (
  <div className="absolute inset-0 pointer-events-none overflow-hidden">
    <div
      className="absolute inset-0"
      style={{
        background: `radial-gradient(circle at center, rgba(139, 92, 246, 0.25), transparent 70%)`,
        mixBlendMode: 'soft-light',
      }}
    />
  </div>
));

// Non-Profit Badge Component
const NonProfitBadge = () => (
  <div className="flex justify-center w-full mb-6">
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="relative inline-flex items-center space-x-2 bg-gradient-to-r from-purple-500/20 to-purple-600/20 rounded-full px-4 py-2 backdrop-blur-lg"
    >
      <HandHeart className="w-5 h-5 text-purple-400" />
      <span className="text-mono-50 text-sm font-medium">100% Non-Profit Event</span>
    </motion.div>
  </div>
);
// Enhanced ScrollingTextLayer with better performance
const ScrollingTextLayer = React.memo(({ text, speed = 1, reverse = false, opacity = 1 }) => (
  <div 
    className="whitespace-nowrap"
    style={{
      animation: `scroll${reverse ? 'Reverse' : ''} ${30 / speed}s linear infinite`,
      opacity
    }}
  >
    {Array(3).fill(text).map((item, index) => (
      <span key={index} className="inline-block mx-8 text-5xl md:text-6xl font-bold">
        <span className="bg-gradient-to-r from-purple-400 via-purple-600 to-purple-400 bg-clip-text text-transparent">
          {item}
        </span>
      </span>
    ))}
  </div>
));

// Optimized StatCard with enhanced animations
const StatCard = React.memo(({ number, label, index }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    transition={{ duration: 0.6, delay: 0.2 * index }}
    className="group relative overflow-hidden rounded-xl bg-gradient-to-br from-surface-100 to-surface-200 backdrop-blur-lg p-6 border border-purple-500/10"
  >
    <div className="absolute inset-0 bg-gradient-to-br from-purple-500/20 to-purple-600/20 opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
    <motion.div
      whileHover={{ scale: 1.02 }}
      className="relative"
    >
      <span className="block text-5xl font-bold bg-gradient-to-r from-purple-400 to-purple-600 bg-clip-text text-transparent mb-2">
        {number}
      </span>
      <span className="text-mono-100 group-hover:text-mono-50 transition-colors duration-300">
        {label}
      </span>
      <motion.div 
        className="absolute -right-2 -bottom-2 w-20 h-20 bg-purple-500/10 rounded-full blur-xl"
        animate={{ 
          scale: [1, 1.2, 1],
          opacity: [0.5, 0.8, 0.5]
        }}
        transition={{ 
          duration: 3,
          repeat: Infinity,
        }}
      />
    </motion.div>
    <Sparkles className="absolute bottom-4 right-4 w-6 h-6 text-purple-400/30 group-hover:text-purple-400/60 transition-colors duration-300" />
  </motion.div>
));


// Enhanced HeroSection Component
const HeroSection = () => {
  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start end", "end start"]
  });

  const categories = [
    {
      title: "AI & Innovation",
      description: "From AI Luminary Awards to MediAI Transformation Prize, celebrating breakthroughs in artificial intelligence",
      icon: Lightbulb
    },
    {
      title: "Software Technology",
      description: "Recognizing excellence from quantum computing to edge computing innovations",
      icon: Globe
    },
    {
      title: "Blockchain & Web3",
      description: "Honoring pioneers in DeFi, NFTs, and enterprise blockchain solutions",
      icon: Users
    }
  ];

  return (
    <div ref={containerRef} className="relative bg-surface-500 min-h-screen overflow-hidden">
      <GridOverlay />
      <Spotlight />
      

      <div className="relative">
        <div className="pt-32 pb-20 px-4">
          <div className="max-w-7xl mx-auto">
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="text-center mb-16"
            >
              <motion.h1
                animate={{ 
                  backgroundPosition: ['0% 50%', '100% 50%', '0% 50%'],
                }}
                transition={{ 
                  duration: 5,
                  repeat: Infinity,
                  ease: "linear"
                }}
                className="text-7xl md:text-8xl font-bold leading-tight mb-6 bg-gradient-to-r from-purple-400 via-purple-600 to-purple-400 bg-clip-text text-transparent bg-[length:200%_auto]"
              >
                <span className="block text-mono-50">International</span>
                <span className="block">Business Excellence</span>
                <span className="block text-mono-50">Awards 2024</span>
              </motion.h1>

              <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5 }}
                className="text-mono-200 text-xl mb-8 max-w-2xl mx-auto"
              >
                Fostering Innovation for Social Impact
              </motion.p>
              <NonProfitBadge />
              <div className="flex flex-col md:flex-row items-center justify-center space-y-4 md:space-y-0 md:space-x-6">
              <a 
                    href="https://forms.gle/rctyFnbXDhM3LXks7" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="inline-block"
                  >
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="group relative px-8 py-4 bg-gradient-to-r from-purple-500 to-purple-600 text-mono-50 rounded-xl overflow-hidden"
                >
                  <div className="absolute inset-0 bg-white/20 translate-y-full group-hover:translate-y-0 transition-transform duration-300" />
                  <span className="relative z-10 flex items-center font-medium">
                    Submit Nomination
                    <ArrowUpRight className="ml-2 w-5 h-5 group-hover:translate-x-1 group-hover:-translate-y-1 transition-transform" />
                  </span>
                </motion.button>
                </a>

                <motion.div
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.7 }}
                  className="flex items-center space-x-6 text-mono-300"
                >
                  <div className="flex items-center">
                    <Calendar className="w-5 h-5 mr-2 text-purple-400" />
                    <span>Dec 08 2024</span>
                  </div>
                  <div className="flex items-center">
                    <MapPin className="w-5 h-5 mr-2 text-purple-400" />
                    <span>Newark, NJ</span>
                  </div>
                </motion.div>
              </div>
            </motion.div>

           

            <div className="grid md:grid-cols-3 gap-8 mb-32">
              {[
                { number: "100+", label: "Tech Leaders & Innovators" },
                { number: "40+", label: "Social Impact Categories" },
                { number: "5+", label: "Countries United for Change" },
              ].map((stat, index) => (
                <StatCard key={index} {...stat} index={index} />
              ))}
            </div>

            <ScrollingTextLayer text="Innovation • Impact • Inclusion" speed={1} />
            <br />
            <ScrollingTextLayer 
              text="AI for Good • Sustainable Tech • Digital Equity" 
              speed={1.5} 
              reverse={true} 
              opacity={0.7}
            />

            <div className="grid md:grid-cols-3 gap-8 mt-32">
              {categories.map((category, index) => (
                <CategoryCard key={index} {...category} index={index} />
              ))}
            </div>
          </div>
        </div>
      </div>

      <style jsx global>{`
        @keyframes scroll {
          0% { transform: translateX(0); }
          100% { transform: translateX(-100%); }
        }
        @keyframes scrollReverse {
          0% { transform: translateX(-100%); }
          100% { transform: translateX(0); }
        }
        @keyframes gridScroll {
          0% { background-position: 0 0; }
          100% { background-position: 0 -300px; }
        }
      `}</style>
    </div>
  );
};

export default HeroSection;