import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Award, Crown, Sparkles, Trophy, Brain, Shield, Code, Database, Gem } from 'lucide-react';

const awards = [
  {
    category: "Artificial Intelligence (AI) Awards",
    icon: Brain,
    awards: [
      { name: "The AI Luminary Award", tagline: "Illuminating the Future of AI", icon: Sparkles,
        criteria: ["Technical Innovation", "Ethical Implementation", "Real-world Impact"] },
      { name: "The Turing Vanguard Prize", tagline: "Pushing the Boundaries of Machine Intelligence", icon: Crown,
        criteria: ["Research Excellence", "Industry Advancement", "Breakthrough Achievement"] },
      { name: "The AI Ethics Beacon Award", tagline: "Guiding AI Towards a Responsible Future", icon: Shield,
        criteria: ["Ethical Leadership", "Responsible Innovation", "Social Impact"] },
    ]
  },
  {
    category: "Software Technology Awards",
    icon: Code,
    awards: [
      { name: "The Code Maestro Lifetime Achievement", tagline: "A Legacy in Code", icon: Crown,
        criteria: ["Technical Excellence", "Industry Influence", "Innovation History"] },
      { name: "The Cyber Sentinel Shield", tagline: "Guardians of the Digital Realm", icon: Shield,
        criteria: ["Security Innovation", "Threat Prevention", "Protection Excellence"] },
      { name: "The Cloud Odyssey Award", tagline: "Navigating the Cloud Frontier", icon: Database,
        criteria: ["Scalability", "Performance", "Architecture Innovation"] },
    ]
  },
  {
    category: "Blockchain & Web3 Awards",
    icon: Gem,
    awards: [
      { name: "The Blockchain Architect Accolade", tagline: "Building the Foundations of Trust", icon: Database,
        criteria: ["Protocol Innovation", "Security Design", "Scalability Solution"] },
      { name: "The Web3 Paradigm Prize", tagline: "Pioneering the Decentralized Web", icon: Crown,
        criteria: ["Decentralization Impact", "User Adoption", "Technical Innovation"] },
      { name: "The DeFi Innovator's Laurel", tagline: "Redefining Finance for the Digital Age", icon: Gem,
        criteria: ["Financial Innovation", "Security Excellence", "Market Impact"] },
    ]
  }
];

const TechAwardsShowcase = () => {
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);
  const [currentAwardIndex, setCurrentAwardIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (!isHovered) {
      const interval = setInterval(() => {
        setCurrentAwardIndex((prevIndex) => {
          const nextIndex = (prevIndex + 1) % awards[currentCategoryIndex].awards.length;
          if (nextIndex === 0) {
            setCurrentCategoryIndex((prevCatIndex) => (prevCatIndex + 1) % awards.length);
          }
          return nextIndex;
        });
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [currentCategoryIndex, isHovered]);

  const currentCategory = awards[currentCategoryIndex];
  const currentAward = currentCategory.awards[currentAwardIndex];
  const AwardIcon = currentAward.icon;
  const CategoryIcon = currentCategory.icon;

  return (
    <div className="relative flex items-center justify-center min-h-screen overflow-hidden bg-gradient-to-br from-surface-500 via-surface-400 to-surface-300 py-20">
      {/* Enhanced background effects */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-radial"></div>
        <div className="absolute inset-0 bg-grid opacity-50"></div>
        
        {/* Dynamic particles */}
        {[...Array(30)].map((_, i) => (
          <motion.div
            key={i}
            className="absolute rounded-full bg-purple-400/20"
            initial={{
              x: Math.random() * window.innerWidth,
              y: Math.random() * window.innerHeight,
              scale: Math.random() * 0.5 + 0.5
            }}
            animate={{
              y: ['-20px', '20px'],
              opacity: [0.2, 0.5, 0.2],
            }}
            transition={{
              duration: Math.random() * 3 + 2,
              repeat: Infinity,
              repeatType: 'reverse',
              ease: 'easeInOut',
            }}
            style={{
              width: `${Math.random() * 4 + 2}px`,
              height: `${Math.random() * 4 + 2}px`,
            }}
          />
        ))}
      </div>

      <div className="relative z-10 max-w-5xl w-full px-4">
        {/* Enhanced header section */}
        <motion.div
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 1 }}
          className="text-center mb-12"
        >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
            className="inline-flex items-center gap-2 px-4 py-2 bg-purple-500/10 rounded-full mb-4"
          >
            <Trophy className="w-5 h-5 text-purple-400" />
            <span className="text-purple-400 text-sm font-medium tracking-[0.2em] uppercase">
              Excellence in Technology
            </span>
          </motion.div>
          
          <h1 className="text-4xl md:text-5xl font-bold bg-gradient-to-r from-purple-300 via-purple-400 to-purple-300 bg-clip-text text-transparent mb-4">
            Global Tech Excellence Awards
          </h1>
          
          <p className="text-xl text-mono-200 max-w-2xl mx-auto">
            Celebrating revolutionary achievements across the technological spectrum
          </p>
        </motion.div>
        
        {/* Enhanced award card */}
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          className="relative backdrop-blur-xl rounded-2xl p-8 md:p-10"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {/* Card background */}
          <div className="absolute inset-0 rounded-2xl">
            <div className="absolute inset-0 rounded-2xl bg-surface-400/20 backdrop-blur-xl" />
            <div className="absolute inset-0 rounded-2xl p-[1px] bg-gradient-to-br from-purple-400/40 via-purple-500/20 to-transparent">
              <div className="h-full w-full rounded-2xl bg-surface-400/20" />
            </div>
          </div>

          {/* Category header */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="relative mb-8 flex items-center justify-center gap-4"
          >
            <CategoryIcon className="w-6 h-6 text-purple-400" />
            <h2 className="text-xl font-medium text-purple-400">{currentCategory.category}</h2>
          </motion.div>
          
          {/* Award content */}
          <div className="relative min-h-[300px]">
            <AnimatePresence mode="wait">
              <motion.div
                key={`${currentCategoryIndex}-${currentAwardIndex}`}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.5 }}
                className="absolute inset-0 flex flex-col items-center justify-center"
              >
                <motion.div
                  initial={{ scale: 0, rotate: -180 }}
                  animate={{ scale: 1, rotate: 0 }}
                  transition={{ duration: 0.5, delay: 0.2 }}
                  className="relative mb-6"
                >
                  <div className="absolute inset-0 blur-xl bg-purple-400/30 rounded-full scale-150" />
                  <AwardIcon className="relative w-20 h-20 text-purple-400" />
                </motion.div>
                
                <motion.h3
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.3 }}
                  className="text-2xl md:text-3xl font-bold mb-3 text-mono-50 text-center"
                >
                  {currentAward.name}
                </motion.h3>
                
                <motion.p
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.4 }}
                  className="text-lg italic text-mono-200 text-center mb-6"
                >
                  {currentAward.tagline}
                </motion.p>

                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.5 }}
                  className="flex flex-wrap justify-center gap-2"
                >
                  {currentAward.criteria.map((criterion, index) => (
                    <span
                      key={index}
                      className="px-3 py-1 bg-purple-500/10 rounded-full text-sm text-purple-300"
                    >
                      {criterion}
                    </span>
                  ))}
                </motion.div>
              </motion.div>
            </AnimatePresence>
          </div>

          {/* Navigation dots */}
          <div className="relative flex justify-center gap-2 mt-8">
            {currentCategory.awards.map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentAwardIndex(index)}
                className={`w-2 h-2 rounded-full transition-all duration-300 ${
                  index === currentAwardIndex 
                    ? 'bg-purple-400 scale-125' 
                    : 'bg-purple-400/30 hover:bg-purple-400/50'
                }`}
              />
            ))}
          </div>

          {/* Decorative elements */}
          <div className="absolute -top-1 -right-1 w-32 h-32 bg-purple-500/10 blur-2xl rounded-full" />
          <div className="absolute -bottom-1 -left-1 w-32 h-32 bg-purple-400/10 blur-2xl rounded-full" />
        </motion.div>
      </div>

      <style jsx>{`
        .bg-gradient-radial {
          background: radial-gradient(circle at center, rgba(139, 92, 246, 0.15) 0%, transparent 70%);
          animation: pulse 8s ease-in-out infinite alternate;
        }

        .bg-grid {
          background-image: 
            linear-gradient(to right, rgba(139, 92, 246, 0.05) 1px, transparent 1px),
            linear-gradient(to bottom, rgba(139, 92, 246, 0.05) 1px, transparent 1px);
          background-size: 40px 40px;
          animation: moveGrid 15s linear infinite;
        }

        @keyframes pulse {
          0% { opacity: 0.5; }
          100% { opacity: 0.8; }
        }

        @keyframes moveGrid {
          0% { transform: translate(0, 0); }
          100% { transform: translate(40px, 40px); }
        }

        @keyframes shimmer {
          0% { opacity: 0.3; }
          50% { opacity: 0.5; }
          100% { opacity: 0.3; }
        }
      `}</style>
    </div>
  );
};

export default TechAwardsShowcase;