import React from 'react';
import { Trophy, Star, Award, Crown } from 'lucide-react';

const LuxuryAwardsHero = () => {
  const lineConfigs = [
    { dashArray: 1500, speed: "4s", opacity: 0.4 },
    { dashArray: 2000, speed: "6s", opacity: 0.3 },    
    { dashArray: 2200, speed: "5s", opacity: 0.35 },
    { dashArray: 1800, speed: "4.5s", opacity: 0.25 },
    { dashArray: 2500, speed: "7s", opacity: 0.3 },
    { dashArray: 1600, speed: "4s", opacity: 0.35 },
    { dashArray: 2300, speed: "6s", opacity: 0.4 },
    { dashArray: 1700, speed: "3s", opacity: 0.3 },
    { dashArray: 2100, speed: "5.5s", opacity: 0.35 },
    { dashArray: 1900, speed: "4.5s", opacity: 0.25 },
    { dashArray: 2400, speed: "6.5s", opacity: 0.3 },
    { dashArray: 1500, speed: "4s", opacity: 0.35 }
  ];

  const generatePath = (startY, index) => {
    const width = 1200;
    const height = 600;
    const startX = width + 200;
    const endX = -400;
    
    const controlPoint1 = {
      x: width * 0.25,
      y: startY + 50
    };
    const controlPoint2 = {
      x: -width * 0.25,
      y: height * 2.75
    };
  
    const endY = startY + (height * 0.4) + (index * 20);
    
    return `M ${startX} ${startY} 
            C ${controlPoint1.x} ${controlPoint1.y}, 
              ${controlPoint2.x} ${controlPoint2.y}, 
              ${endX} ${endY}`;
  };

  const awardCategories = [
    { icon: Trophy, label: "AI Excellence" },
    { icon: Star, label: "Innovation" },
    { icon: Award, label: "Leadership" },
    { icon: Crown, label: "Visionary" }
  ];

  return (
    <div className="min-h-screen bg-surface-500 flex items-center justify-center p-6">
      <div className="relative w-full max-w-6xl overflow-hidden rounded-2xl border border-purple-500/20">
        {/* Background and Lines Layer */}
        <div className="absolute inset-0">
          {/* Base Background */}
          <div className="absolute inset-0 bg-surface-300/50 backdrop-blur-xl" />
          
          {/* Gradient Overlay */}
          <div className="absolute inset-0 bg-gradient-to-br from-purple-500/5 to-transparent" />
          
          {/* Animated Lines */}
          <div className="absolute inset-0">
            <svg className="w-full h-full" preserveAspectRatio="none" viewBox="0 0 1200 600">
              <defs>
                <linearGradient id="lineGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                  <stop offset="0%" stopColor="#8b5cf6" stopOpacity="0.6" />
                  <stop offset="50%" stopColor="#9d72ff" stopOpacity="0.8" />
                  <stop offset="100%" stopColor="#8b5cf6" stopOpacity="0.6" />
                </linearGradient>
              </defs>
              {lineConfigs.map((config, index) => {
                const startY = -50 + index * 40;
                
                return (
                  <g key={index}>
                    {/* Main line */}
                    <path
                      d={generatePath(startY, index)}
                      stroke="url(#lineGradient)"
                      strokeWidth="1.5"
                      fill="none"
                      className="animated-line"
                      style={{
                        filter: 'blur(0.5px)',
                        opacity: config.opacity,
                        animationDuration: config.speed
                      }}
                    />
                    {/* Enhanced glow effect */}
                    <path
                      d={generatePath(startY, index)}
                      stroke="#9d72ff"
                      strokeWidth="1"
                      fill="none"
                      className="animated-line"
                      style={{
                        filter: 'blur(4px)',
                        opacity: config.opacity * 0.5,
                        animationDuration: config.speed
                      }}
                    />
                  </g>
                );
              })}
            </svg>
          </div>

          {/* Content Background Overlay for better readability */}
          <div className="absolute inset-0 bg-gradient-to-r from-surface-300/80 via-surface-300/70 to-transparent" />
        </div>

        {/* Content Layer */}
        <div className="relative z-10 min-h-[600px] p-8 md:p-12">
          <div className="flex flex-col items-start">
            {/* Award Badge */}
            <div className="inline-flex items-center gap-2 px-4 py-2 bg-purple-500/10 rounded-full backdrop-blur-sm mb-8">
              <Trophy className="w-4 h-4 text-purple-400" />
              <span className="text-purple-400 text-sm font-medium tracking-wider uppercase">
                Awards 2024
              </span>
            </div>

            {/* Main Content */}
            <div className="space-y-8 max-w-3xl">
              <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold tracking-tight">
                <span className="text-mono-50">Celebrating</span>
                <br />
                <span className="bg-gradient-to-r from-purple-400 to-purple-600 bg-clip-text text-transparent">
                  Business Excellence
                </span>
              </h1>

              <p className="text-mono-200 text-lg md:text-xl leading-relaxed">
                Join us in honoring the most innovative minds and groundbreaking achievements 
                in technology. Where excellence meets recognition.
              </p>

              {/* Award Categories Grid */}
              <div className="grid grid-cols-2 md:grid-cols-4 gap-4 my-8">
                {awardCategories.map(({ icon: Icon, label }, index) => (
                  <div 
                    key={index}
                    className="group flex flex-col items-center justify-center p-4 rounded-xl bg-surface-300/50 backdrop-blur-sm border border-purple-500/10 hover:bg-purple-500/10 transition-all duration-300"
                  >
                    <Icon className="w-6 h-6 text-purple-400 mb-2 group-hover:scale-110 transition-transform duration-300" />
                    <span className="text-mono-200 text-sm">{label}</span>
                  </div>
                ))}
              </div>

              {/* Action Buttons */}
              <div className="flex flex-col sm:flex-row gap-4">
              <a
          href="https://forms.gle/rctyFnbXDhM3LXks7" 
          target="_blank" 
          rel="noopener noreferrer"
          className="inline-block">
                <button className="group px-8 py-4 bg-purple-500 rounded-xl text-white flex items-center justify-center gap-3 hover:bg-purple-600 transition-all duration-300">
                  <span className="font-medium">Submit Nomination</span>
                  <svg 
                    className="w-5 h-5 transform group-hover:translate-x-1 transition-transform duration-300" 
                    viewBox="0 0 24 24" 
                    fill="none" 
                    stroke="currentColor"
                  >
                    <path 
                      d="M5 12h14m-7-7l7 7-7 7" 
                      strokeWidth="2" 
                      strokeLinecap="round" 
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                </a>

                <a
          href="https://forms.gle/7DtdXr17fGqnWKhs7" 
          target="_blank" 
          rel="noopener noreferrer"
          className="inline-block">
                <button className="group px-8 py-4 border border-purple-500/50 text-purple-400 rounded-xl flex items-center justify-center gap-3 hover:bg-purple-500/10 transition-all duration-300">
                  <span className="font-medium">Become our free sponsor</span>
                  <svg 
                    className="w-5 h-5 transform group-hover:translate-x-1 transition-transform duration-300" 
                    viewBox="0 0 24 24" 
                    fill="none" 
                    stroke="currentColor"
                  >
                    <path 
                      d="M5 12h14m-7-7l7 7-7 7" 
                      strokeWidth="2" 
                      strokeLinecap="round" 
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .animated-line {
          stroke-dasharray: 3000;
          stroke-dashoffset: 3000;
          animation: runLine linear infinite;
        }

        @keyframes runLine {
          to {
            stroke-dashoffset: 0;
          }
        }
      `}</style>
    </div>
  );
};

export default LuxuryAwardsHero;