import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Award, Star, Trophy, Lightbulb, Code, Database, Shield, Brain } from 'lucide-react';

const awardCategories = [
  { 
    name: 'AI Excellence',
    icon: Brain, 
    description: 'Recognizing groundbreaking achievements in artificial intelligence and machine learning.',
    criteria: [
      'Technical Innovation Impact',
      'Ethical AI Implementation',
      'Scalability & Performance',
      'Real-world Applications'
    ]
  },
  { 
    name: 'Software Innovation',
    icon: Code, 
    description: 'Honoring exceptional software solutions that transform industry standards.',
    criteria: [
      'Architecture Excellence',
      'User Experience Design',
      'Technical Performance',
      'Market Impact'
    ]
  },
  { 
    name: 'Blockchain Pioneer',
    icon: Database, 
    description: 'Celebrating revolutionary advances in blockchain and Web3 technologies.',
    criteria: [
      'Protocol Innovation',
      'Security Implementation',
      'Scalability Solutions',
      'Industry Application'
    ]
  },
  { 
    name: 'Security Leadership',
    icon: Shield, 
    description: 'Recognizing outstanding achievements in cybersecurity and data protection.',
    criteria: [
      'Security Architecture',
      'Threat Prevention',
      'Privacy Protection',
      'Compliance Excellence'
    ]
  },
  { 
    name: 'Rising Star',
    icon: Star, 
    description: 'Highlighting emerging technologies and innovations shaping the future.',
    criteria: [
      'Innovation Potential',
      'Market Disruption',
      'Technical Excellence',
      'Growth Trajectory'
    ]
  },
  { 
    name: 'Tech Leadership',
    icon: Trophy, 
    description: 'Acknowledging visionary leadership in technological advancement.',
    criteria: [
      'Vision & Strategy',
      'Industry Impact',
      'Innovation Culture',
      'Sustainable Growth'
    ]
  },
  { 
    name: 'Innovation Impact',
    icon: Lightbulb, 
    description: 'Recognizing solutions that create significant societal and industry impact.',
    criteria: [
      'Social Impact',
      'Industry Transformation',
      'Sustainability Focus',
      'Implementation Success'
    ]
  }
];

const CircularRadarChart = () => {
  const [lineAngle, setLineAngle] = useState(0);
  const [size, setSize] = useState({ width: 350, height: 350 });
  const [activeCategory, setActiveCategory] = useState(awardCategories[0]);
  const [isAutoRotating, setIsAutoRotating] = useState(true);

  const updateSize = useCallback(() => {
    const width = window.innerWidth;
    let chartSize;
    
    if (width < 768) {
      chartSize = width * 0.8;
    } else if (width < 1024) {
      chartSize = 400;
    } else {
      chartSize = 450;
    }
    
    chartSize = Math.min(chartSize, 500);
    setSize({ width: chartSize, height: chartSize });
  }, []);

  useEffect(() => {
    updateSize();
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, [updateSize]);

  useEffect(() => {
    let intervalId;
    if (isAutoRotating) {
      intervalId = setInterval(() => {
        setLineAngle((prevAngle) => {
          const newAngle = (prevAngle + 0.02) % (2 * Math.PI);
          const activeIndex = Math.floor((newAngle + Math.PI / awardCategories.length) / (2 * Math.PI / awardCategories.length)) % awardCategories.length;
          setActiveCategory(awardCategories[activeIndex]);
          return newAngle;
        });
      }, 50);
    }
    return () => clearInterval(intervalId);
  }, [isAutoRotating]);

  const radius = useMemo(() => Math.min(size.width, size.height) * 0.35, [size]);
  const center = useMemo(() => ({ x: size.width / 2, y: size.height / 2 }), [size]);

  const getLineCoordinates = useCallback((angle) => ({
    x: center.x + Math.cos(angle - Math.PI / 2) * radius,
    y: center.y + Math.sin(angle - Math.PI / 2) * radius
  }), [center, radius]);

  const lineEnd = getLineCoordinates(lineAngle);

  const isIconActive = useCallback((index) => {
    const totalIcons = awardCategories.length;
    const sectorSize = (2 * Math.PI) / totalIcons;
    const iconAngle = (index / totalIcons) * 2 * Math.PI;
    let adjustedLineAngle = (lineAngle + sectorSize / 4 + 2 * Math.PI) % (2 * Math.PI);
    let angleDiff = Math.abs(adjustedLineAngle - iconAngle);
    angleDiff = Math.min(angleDiff, 2 * Math.PI - angleDiff);
    return angleDiff < sectorSize / 2;
  }, [lineAngle]);

  const handleCategoryClick = (category, index) => {
    if (activeCategory?.name === category.name) {
      setIsAutoRotating(!isAutoRotating);
    } else {
      setActiveCategory(category);
      setIsAutoRotating(false);
      setLineAngle((index / awardCategories.length) * 2 * Math.PI);
    }
  };

  return (
    <div className="min-h-screen bg-surface-500 py-20 px-4 md:px-8 lg:px-12"
      style={{
        backgroundImage: `radial-gradient(ellipse at center, rgba(139, 92, 246, 0.15), transparent 70%)`,
      }}
    >
      <div className="text-center mb-20">
        <h4 className="text-purple-400 text-sm font-medium tracking-[0.2em] uppercase mb-3">
          Award Categories
        </h4>
        <h2 className="text-4xl md:text-5xl lg:text-6xl font-bold bg-gradient-to-r from-purple-300 via-purple-400 to-purple-300 bg-clip-text text-transparent mb-6">
          Excellence Recognition
        </h2>
        <div className="w-24 h-1 bg-gradient-to-r from-purple-500 to-purple-400 mx-auto rounded-full" />
      </div>

      <div className="max-w-6xl mx-auto flex flex-col lg:flex-row items-center lg:items-start justify-center gap-12">
        {/* Category Details Card */}
        <div className="w-full lg:w-1/3 order-2 lg:order-1">
          <div className="relative group">
            <div className="absolute inset-0 bg-purple-500/5 backdrop-blur-xl rounded-2xl" />
            
            <div className="absolute inset-0 rounded-2xl p-[1px] bg-gradient-to-br from-purple-400/40 via-purple-500/20 to-transparent">
              <div className="h-full w-full rounded-2xl bg-surface-400/20" />
            </div>

            <div className="relative p-6 sm:p-8">
              <div className="mb-8">
                <div className="inline-flex items-center gap-3 px-4 py-2 bg-purple-500/10 rounded-full mb-4">
                  <activeCategory.icon className="w-5 h-5 text-purple-400" />
                  <span className="text-purple-300 font-medium">{activeCategory.name}</span>
                </div>
                <p className="text-mono-100 text-lg leading-relaxed">
                  {activeCategory.description}
                </p>
              </div>

              <div className="space-y-4">
                <h3 className="text-purple-400 text-sm font-medium uppercase tracking-wider mb-4">
                  Judging Criteria
                </h3>
                {activeCategory.criteria.map((criterion, index) => (
                  <div 
                    key={index}
                    className="flex items-start gap-3 text-mono-200 group/item hover:text-mono-100 transition-colors duration-300"
                  >
                    <div className="flex-shrink-0 mt-1.5">
                      <div className="w-1.5 h-1.5 rounded-full bg-purple-400 group-hover/item:scale-125 transition-transform duration-300" />
                    </div>
                    <span className="text-sm sm:text-base">{criterion}</span>
                  </div>
                ))}
              </div>

              <div className="absolute -top-1 -right-1 w-20 h-20 bg-purple-500/10 blur-2xl rounded-full" />
              <div className="absolute -bottom-2 -left-2 w-24 h-24 bg-purple-400/10 blur-2xl rounded-full" />
            </div>

            <div className={`absolute top-6 right-6 flex items-center gap-2 ${isAutoRotating ? 'text-purple-400' : 'text-mono-300'}`}>
              <div className={`w-2 h-2 rounded-full ${isAutoRotating ? 'bg-purple-400 animate-pulse' : 'bg-mono-300'}`} />
              <span className="text-xs uppercase tracking-wider">
                {isAutoRotating ? 'Auto' : 'Manual'}
              </span>
            </div>
          </div>
        </div>

        {/* Interactive Radar Chart */}
        <div className="relative order-1 lg:order-2" style={{ width: `${size.width}px`, height: `${size.height}px` }}>
          <svg className="w-full h-full">
            {[0.2, 0.4, 0.6, 0.8, 1].map((scale, index) => (
              <circle
                key={index}
                cx={center.x}
                cy={center.y}
                r={radius * scale}
                fill="none"
                stroke="rgba(139, 92, 246, 0.2)"
                strokeWidth="1"
              />
            ))}
            <line
              x1={center.x}
              y1={center.y}
              x2={lineEnd.x}
              y2={lineEnd.y}
              stroke="#8b5cf6"
              strokeWidth="2"
            />
          </svg>
          
          {awardCategories.map((category, index) => {
            const angle = (index / awardCategories.length) * 2 * Math.PI - Math.PI / 2;
            const x = center.x + Math.cos(angle) * (radius + radius * 0.2);
            const y = center.y + Math.sin(angle) * (radius + radius * 0.2);
            const isActive = isIconActive(index);
            
            return (
              <div
                key={category.name}
                className="absolute transform -translate-x-1/2 -translate-y-1/2 transition-all duration-300 cursor-pointer"
                style={{ left: `${x}px`, top: `${y}px` }}
                onClick={() => handleCategoryClick(category, index)}
              >
                <div 
                  className={`
                    w-12 h-12 rounded-lg flex items-center justify-center transition-all duration-500
                    ${isActive 
                      ? 'bg-purple-500/20 shadow-lg shadow-purple-500/30 scale-110' 
                      : 'bg-surface-300 hover:bg-surface-300/80'}
                    ${activeCategory?.name === category.name && !isAutoRotating 
                      ? 'ring-2 ring-purple-400/50' 
                      : ''}
                  `}
                >
                  <category.icon 
                    className={`
                      w-6 h-6 transition-all duration-300
                      ${isActive ? 'text-purple-400 scale-110' : 'text-mono-200'}
                    `}
                  />
                </div>
                {isActive && (
                  <div className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 text-xs text-purple-400 whitespace-nowrap animate-fadeIn">
                    {category.name}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CircularRadarChart;