import React, { useRef, useState, useEffect } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { SpotLight, Text, PerspectiveCamera, Stars, Float, Html } from '@react-three/drei'; 
import * as THREE from 'three';
import LogoIconSvg from '../Components/Logo'; 

const Scene = () => {
  const spotLightCenter = useRef();
  const spotLightLeft = useRef();
  const spotLightRight = useRef();
  const [leftTarget] = useState(() => new THREE.Object3D());
  const [rightTarget] = useState(() => new THREE.Object3D());
  const [textPosition, setTextPosition] = useState([0, 0, 0]);
  const [fontSize, setFontSize] = useState(0.5);

  const { camera, size } = useThree();

  useEffect(() => {
    const updateLayout = () => {
      const fov = camera.fov * (Math.PI / 180);
      const visibleHeight = 2 * Math.tan(fov / 2) * Math.abs(camera.position.z);
      const y = visibleHeight / 4;
      setTextPosition([0, y, 0]);

      const baseFontSize = 0.5;
      const scaleFactor = Math.min(size.width / 1920, 1);
      setFontSize(baseFontSize * scaleFactor);
    };

    updateLayout();
    window.addEventListener('resize', updateLayout);
    return () => window.removeEventListener('resize', updateLayout);
  }, [camera, size]);

  useFrame(({ clock }) => {
    const time = clock.getElapsedTime();

    if (spotLightCenter.current) spotLightCenter.current.target.position.set(0, -0.5, 0);

    const xOffset = Math.sin(time * 0.3) * 3;
    const zOffset = Math.cos(time * 0.3) * 2;

    leftTarget.position.set(-3 + xOffset, -0.5, zOffset);
    rightTarget.position.set(3 - xOffset, -0.5, -zOffset);
  });

  return (
    <>
      <PerspectiveCamera makeDefault position={[0, 2, 5]} />
      <fog attach="fog" args={['#09080C', 1, 20]} /> {/* Fog for depth */}

      <SpotLight
        ref={spotLightCenter}
        position={[0, 5, 0]}
        angle={0.3}
        penumbra={0.7}
        intensity={2}
        distance={12}
        castShadow
        shadow-mapSize={[2048, 2048]}
        color="#fff"
      />

      <SpotLight
        ref={spotLightLeft}
        position={[-3, 5, 0]}
        target={leftTarget}
        angle={0.3}
        penumbra={0.7}
        intensity={1.5}
        distance={12}
        castShadow
        shadow-mapSize={[2048, 2048]}
        color="#7c3aed"
      />

      <SpotLight
        ref={spotLightRight}
        position={[3, 5, 0]}
        target={rightTarget}
        angle={0.3}
        penumbra={0.7}
        intensity={1.5}
        distance={12}
        castShadow
        shadow-mapSize={[2048, 2048]}
        color="#9d72ff"
      />

      <primitive object={leftTarget} />
      <primitive object={rightTarget} />

      {/* Logo overlay with a smoother scaling animation */}
      <Float speed={2} rotationIntensity={0.2} floatIntensity={0.5}>
        <Html position={[0, textPosition[1] + fontSize * 2.5, textPosition[2]]} center>
          <div style={{ width: '120px', height: 'auto', marginBottom:'125px', animation: 'fadeIn 2s ease-in-out' }}>
            <LogoIconSvg />
          </div>
         
        </Html>

        {/* Main Title with Gradient Effect */}
        <Text
          position={[textPosition[0], textPosition[1] + fontSize * 1.2, textPosition[2]]}
          fontSize={fontSize * 1.2} // Larger font size for emphasis
          color="#fff"
          anchorX="center"
          anchorY="middle"
          fontWeight="bold"
          letterSpacing={0.1}
          style={{ 
            backgroundImage: 'linear-gradient(45deg, #9d72ff, #7c3aed)', 
            WebkitBackgroundClip: 'text', 
            WebkitTextFillColor: 'transparent',
            textShadow: '3px 3px 10px rgba(0,0,0,0.5)',
            animation: 'fadeInScale 1s ease-in-out'
          }}
        >
          ONESEA MEDIA
        </Text>

        {/* Subtitle with subtle entrance */}
        <Text
          position={[textPosition[0], textPosition[1], textPosition[2]]}
          fontSize={fontSize * 0.8} // Adjusted for better visibility
          color="#ddd"
          anchorX="center"
          anchorY="middle"
          letterSpacing={0.05}
          style={{ 
            textShadow: '2px 2px 6px rgba(0,0,0,0.5)',
            animation: 'fadeIn 2s ease-in-out 0.5s'
          }}
        >
          Global Business Excellence Awards
        </Text>

        {/* Year */}
        <Text
          position={[textPosition[0], textPosition[1] - fontSize * 1.2, textPosition[2]]}
          fontSize={fontSize * 0.6}
          color="#fff"
          anchorX="center"
          anchorY="middle"
          style={{ textShadow: '1px 1px 3px rgba(0,0,0,0.8)', animation: 'fadeIn 2.5s ease-in-out' }}
        >
          2024
        </Text>
      </Float>

      <Stars 
        radius={100} 
        depth={50} 
        count={7000} 
        factor={4} 
        saturation={0} 
        fade 
        speed={0.5} 
      />

      <ambientLight intensity={0.1} />
    </>
  );
};

const OnseaStage = () => {
  return (
    <div style={{ width: '100vw', height: '100vh', background: '#09080C' }}>
      <Canvas shadows dpr={[1, 2]}>
        <Scene />
      </Canvas>
    </div>
  );
};

export default OnseaStage;

/* CSS Animations */
const fadeInKeyframes = `
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}
@keyframes fadeInScale {
  from { opacity: 0; transform: scale(0.95); }
  to { opacity: 1; transform: scale(1); }
}
`;

// Add these keyframes to your CSS for smooth fade-in animations
const styleTag = document.createElement('style');
styleTag.innerHTML = fadeInKeyframes;
document.head.appendChild(styleTag);
