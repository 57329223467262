import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { Sparkles, Trophy, Crown, Star, Award } from 'lucide-react';

const GlowingAwards = () => {
  const [animationStep, setAnimationStep] = useState(0);
  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: false // Changed to false to trigger animation on each view
  });

  useEffect(() => {
    if (inView) {
      setAnimationStep(0); // Reset animation
      const timer1 = setTimeout(() => setAnimationStep(1), 200);
      const timer2 = setTimeout(() => setAnimationStep(2), 1500);
      return () => {
        clearTimeout(timer1);
        clearTimeout(timer2);
      };
    }
  }, [inView]);

  const Particles = () => {
    return (
      <div className="absolute inset-0 pointer-events-none">
        {[...Array(20)].map((_, i) => (
          <div
            key={i}
            className="absolute rounded-full"
            style={{
              width: `${Math.random() * 3 + 1}px`,
              height: `${Math.random() * 3 + 1}px`,
              background: `rgba(139, 92, 246, ${Math.random() * 0.3 + 0.1})`,
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
              animation: `float ${Math.random() * 3 + 2}s ease-in-out infinite`,
              animationDelay: `${Math.random() * 2}s`
            }}
          />
        ))}
      </div>
    );
  };

  const FloatingIcons = () => {
    const icons = [Trophy, Star, Award];
    return (
      <div className="absolute inset-0 pointer-events-none">
        {icons.map((Icon, index) => (
          <motion.div
            key={index}
            className="absolute"
            initial={{ opacity: 0 }}
            animate={animationStep >= 2 ? {
              opacity: [0.2, 0.5, 0.2],
              y: [-10, 10, -10],
            } : {}}
            transition={{
              duration: 4,
              repeat: Infinity,
              delay: index * 1.5,
            }}
            style={{
              left: `${20 + index * 30}%`,
              top: '30%',
            }}
          >
            <Icon className="w-8 h-8 text-purple-400/30" />
          </motion.div>
        ))}
      </div>
    );
  };

  return (
    <div 
      ref={ref}
      className="w-full h-[80vh] bg-gradient-to-br from-surface-500 to-surface-400 flex flex-col items-center justify-center relative overflow-hidden"
    >
      <Particles />
      <FloatingIcons />

      {/* Decorative lines */}
      <div className="absolute inset-0">
        {['-left', '-right'].map((direction, index) => (
          <React.Fragment key={index}>
            <motion.div 
              className={`absolute top-[15%] ${direction}-0 w-32 h-px`}
              initial={{ opacity: 0, scaleX: 0 }}
              animate={animationStep >= 1 ? { opacity: 1, scaleX: 1 } : {}}
              transition={{ duration: 1, delay: index * 0.2 }}
              style={{
                background: `linear-gradient(${direction === '-left' ? 'to right' : 'to left'}, transparent, rgba(139, 92, 246, 0.2), transparent)`,
                transformOrigin: direction === '-left' ? 'left' : 'right'
              }}
            />
            <motion.div 
              className={`absolute bottom-[15%] ${direction}-0 w-32 h-px`}
              initial={{ opacity: 0, scaleX: 0 }}
              animate={animationStep >= 1 ? { opacity: 1, scaleX: 1 } : {}}
              transition={{ duration: 1, delay: index * 0.2 + 0.2 }}
              style={{
                background: `linear-gradient(${direction === '-left' ? 'to right' : 'to left'}, transparent, rgba(139, 92, 246, 0.2), transparent)`,
                transformOrigin: direction === '-left' ? 'left' : 'right'
              }}
            />
          </React.Fragment>
        ))}
      </div>

      {/* Light beam effect */}
      <motion.div 
        className="absolute top-[15%] left-1/2 transform -translate-x-1/2 w-[1px] h-0 bg-purple-500"
        initial={{ height: 0 }}
        animate={animationStep >= 1 ? { height: '40px' } : {}}
        transition={{ duration: 1 }}
      />
      
      <div 
        className={`absolute top-[15%] left-1/2 transform -translate-x-1/2 w-full h-[35%] transition-all duration-1000 ease-out ${
          animationStep >= 2 ? 'opacity-100 scale-y-100' : 'opacity-0 scale-y-0'
        }`}
        style={{
          background: 'radial-gradient(ellipse at top, rgba(139, 92, 246, 0.4) 0%, rgba(139, 92, 246, 0.1) 40%, transparent 70%)',
          clipPath: 'polygon(30% 0%, 70% 0%, 100% 100%, 0% 100%)',
          filter: 'blur(30px)',
          transformOrigin: 'top',
        }}
      />

      {/* Content section */}
      <div className={`relative z-10 text-center transition-all duration-1000 ${
        animationStep >= 2 ? 'transform -translate-y-12' : 'mt-24'
      }`}>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={animationStep >= 2 ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.6, delay: 0.2 }}
          className="relative inline-block mb-6"
        >
          <Crown className="absolute -top-6 -left-6 w-5 h-5 text-purple-400/50" />
          <span className="text-purple-400 text-sm font-medium tracking-[0.2em] uppercase">
            Global Recognition
          </span>
          <Sparkles className="absolute -bottom-6 -right-6 w-5 h-5 text-purple-400/50" />
        </motion.div>

        <motion.h1 
          initial={{ opacity: 0, y: 20 }}
          animate={animationStep >= 2 ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.6, delay: 0.3 }}
          className="text-5xl md:text-6xl font-bold text-mono-50 mb-4"
        >
          Celebrating
          <span className="bg-gradient-to-r from-purple-400 to-purple-600 bg-clip-text text-transparent"> Excellence</span>
        </motion.h1>

        <motion.p 
          initial={{ opacity: 0, y: 20 }}
          animate={animationStep >= 2 ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.6, delay: 0.4 }}
          className="text-xl md:text-2xl text-mono-200 mb-8 max-w-2xl mx-auto"
        >
          Recognizing groundbreaking achievements in technology and innovation
        </motion.p>

        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={animationStep >= 2 ? { opacity: 1, scale: 1 } : {}}
          transition={{ duration: 0.6, delay: 0.5 }}
          className="flex justify-center gap-4"
        >
          <div className="relative group">
            <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-500 to-purple-600 rounded-xl blur opacity-30 group-hover:opacity-50 transition duration-300"></div>
            <a
          href="https://forms.gle/rctyFnbXDhM3LXks7" 
          target="_blank" 
          rel="noopener noreferrer"
          className="inline-block">
            <button className="relative flex items-center gap-2 px-6 py-3 bg-surface-400/80 hover:bg-surface-400/90 border border-purple-500/20 rounded-xl text-purple-400 transition-all duration-300 group">
              <Trophy className="w-5 h-5 transition-transform duration-300 group-hover:scale-110" />
              <span className="font-medium">Submit Nomination</span>
            </button>
            </a>
          </div>

          <div className="relative group">
            <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-500 to-purple-600 rounded-xl blur opacity-20 group-hover:opacity-40 transition duration-300"></div>
            <a
          href="https://forms.gle/7DtdXr17fGqnWKhs7" 
          target="_blank" 
          rel="noopener noreferrer"
          className="inline-block">
            <button className="relative flex items-center gap-2 px-6 py-3 bg-surface-400/60 hover:bg-surface-400/70 border border-purple-500/20 rounded-xl text-purple-400 transition-all duration-300 group">
              <Star className="w-5 h-5 transition-transform duration-300 group-hover:scale-110" />
              <span className="font-medium">Become our Sponsor for Free</span>
            </button>
</a>
          </div>
        </motion.div>
      </div>

      <style jsx>{`
        @keyframes float {
          0%, 100% { transform: translateY(0) scale(1); opacity: 0.3; }
          50% { transform: translateY(-20px) scale(1.1); opacity: 0.6; }
        }
      `}</style>
    </div>
  );
};

export default GlowingAwards;