import React, { useState, useRef, useEffect, useCallback, memo } from 'react';
import { 
  ChevronDown, 
  Menu,
  BarChart,
  Shield,
  Zap,
  Building2,
  Heart,
  Briefcase,
  Users,
  BookOpen,
  Code,
  MessageCircle,
  X,
  ArrowRight,
  ChevronRight,
  CalendarClock,
  Video,
  Globe,
  Eye,
  Cpu,
  Laugh,
  Award
} from 'lucide-react';

// Menu data - now with both submenu and direct link items
const MENU_ITEMS = [
  {
    title: 'Our Motive',
    icon: Eye,
    href: '#motive'
  },
  {
    title: 'Benefits',
    icon: Laugh,
    href: '#benefits'
  },
  {
    title: 'Our Process',
    icon: Cpu,
    href: '#our_process'
  },
  {
    title: 'Past Events',
    icon: CalendarClock,
    submenu: [
      {
        category: 'Our Events',
        items: [
          { 
            title: 'India Event', 
            description: 'Our Previous Event',
            link:'#previous',
            icon: Globe
          },
          { 
            title: 'Event Promo', 
            link:'#event_promo',
            description: 'Watch promo of our past event',
            icon: Video,
            badge: 'Hot'
          },
          { 
            title: 'Past Winners', 
            link:'#past_winners',
            description: 'Take a look at past winners',
            icon: Award
          }
        ]
      }
    ]
  },
];

// Memoized MenuItem component - now handles both submenu and direct link items
const MenuItem = memo(({ item, isActive, onClick }) => {
  const Icon = item.icon;
  
  if (item.href) {
    return (
      <a
        href={item.href}
        className="flex items-center gap-2 px-4 py-2 rounded-xl
          text-white/90 hover:text-white transition-all duration-200
          hover:bg-white/10"
      >
        <Icon className="w-5 h-5" />
        <span className="font-medium">{item.title}</span>
      </a>
    );
  }

  return (
    <button
      onClick={onClick}
      className={`flex items-center gap-2 px-4 py-2 rounded-xl
        text-white/90 hover:text-white transition-all duration-200
        hover:bg-white/10 ${isActive ? 'bg-white/10 text-white scale-105' : ''}`}
    >
      <Icon className="w-5 h-5" />
      <span className="font-medium">{item.title}</span>
      <ChevronDown className={`w-4 h-4 transition-transform duration-300
        ${isActive ? 'rotate-180' : ''}`} 
      />
    </button>
  );
});

// Mobile menu component - updated to handle direct links
const MobileMenu = memo(({ isOpen, onClose, activeItem, setActiveItem }) => {
  const menuContentRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuContentRef.current && !menuContentRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 bg-black/60 backdrop-blur-xl">
      <div 
        ref={menuContentRef}
        className="relative w-full max-w-sm h-full bg-black/40"
      >
        <div className="h-full flex flex-col">
          {/* Header */}
          <div className="flex items-center justify-between p-4 border-b border-white/10">
            <div className="flex items-center gap-3">
               <a href="/">
      <div className="p-2 rounded-xl bg-white/10">
        <div className="flex items-center gap-2">
          <img 
            src="/onesea.png" 
            alt="ONESEA" 
            className="w-6 h-6 grayscale"
          />
          <span className="text-lg font-bold text-white">ONESEA</span>
        </div>
      </div>
    </a>

            </div>
            <button
              onClick={onClose}
              className="p-2 hover:bg-white/10 rounded-lg transition-colors"
            >
              <X className="h-5 w-5 text-white" />
            </button>
          </div>

          {/* Menu Items */}
          <div className="flex-1 overflow-y-auto py-4">
            {MENU_ITEMS.map((item, index) => {
              if (item.href) {
                return (
                  <div key={index} className="px-4 mb-4">
                    <a
                      href={item.href}
                      className="flex items-center gap-3 p-3 text-white 
                        hover:bg-white/10 rounded-xl transition-colors"
                    >
                      <item.icon className="w-5 h-5" />
                      <span className="font-medium">{item.title}</span>
                    </a>
                  </div>
                );
              }

              return (
                <div key={index} className="px-4 mb-4">
                  <button
                    onClick={() => setActiveItem(activeItem === index ? null : index)}
                    className="flex items-center justify-between w-full p-3 text-white 
                      hover:bg-white/10 rounded-xl transition-colors"
                  >
                    <div className="flex items-center gap-3">
                      <item.icon className="w-5 h-5" />
                      <span className="font-medium">{item.title}</span>
                    </div>
                    <ChevronRight className={`w-4 h-4 transition-transform duration-300
                      ${activeItem === index ? 'rotate-90' : ''}`} 
                    />
                  </button>

                  {/* Submenu */}
                  {activeItem === index && item.submenu && (
                    <div className="mt-2 space-y-1">
                      {item.submenu.map((section, sectionIndex) => (
                        <div key={sectionIndex} className="pt-2">
                          <div className="text-xs font-medium text-white/60 uppercase tracking-wider px-3 mb-2">
                            {section.category}
                          </div>
                          {section.items.map((subItem, subIndex) => {
                            const SubIcon = subItem.icon;
                            const link = subItem.link;

                            return (
                              <a
                                key={subIndex}
                                href={link}
                          
                                className="flex items-center gap-3 p-3 hover:bg-white/10 rounded-xl transition-colors"
                              >
                                <div className="w-8 h-8 flex items-center justify-center rounded-lg bg-white/10">
                                  <SubIcon className="w-4 h-4 text-white" />
                                </div>
                                <div>
                                  <div className="flex items-center gap-2">
                                    <span className="text-sm font-medium text-white">
                                      {subItem.title}
                                    </span>
                                    {subItem.badge && (
                                      <span className="px-2 py-0.5 text-xs font-medium bg-white/10 
                                        text-white/90 rounded-full">
                                        {subItem.badge}
                                      </span>
                                    )}
                                  </div>
                                  <span className="text-sm text-white/70">
                                    {subItem.description}
                                  </span>
                                </div>
                              </a>
                            );
                          })}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          {/* Use App Button */}
          <div className="p-4 border-t border-white/10">
            <a
              href="/"
              className="flex items-center justify-center gap-2 w-full px-4 py-2 
                bg-white text-black rounded-xl font-medium hover:bg-white/90 
                transition-colors" href="https://forms.gle/rctyFnbXDhM3LXks7">
              Nominate
              <ArrowRight className="w-4 h-4" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
});

// Main navigation component
const OptimizedNav = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [activeMobileItem, setActiveMobileItem] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape') {
        setIsMobileMenuOpen(false);
      }
    };

    window.addEventListener('keydown', handleEscape);
    return () => window.removeEventListener('keydown', handleEscape);
  }, []);

  useEffect(() => {
    document.body.style.overflow = isMobileMenuOpen ? 'hidden' : '';
    return () => {
      document.body.style.overflow = '';
    };
  }, [isMobileMenuOpen]);

  return (
    <>
      <div className="fixed top-0 left-0 right-0 z-50 flex justify-center">
        <nav 
          className={`fixed transition-all duration-500 ease-in-out ${
            isScrolled ? 'w-[90%] mt-6 rounded-2xl shadow-2xl' : 'w-full'
          }`}
        >
          {isScrolled && (
            <div className="absolute inset-0 bg-black/20 backdrop-blur-xl rounded-2xl border border-white/10" />
          )}
          
          <div className={`relative mx-auto transition-all duration-500 ease-in-out
            ${isScrolled ? 'max-w-7xl px-6 py-4' : 'max-w-7xl px-8 py-6'}`}
          >
            <div className="flex items-center justify-between">
              {/* Logo */}
              <div className="flex items-center space-x-6">
                  <a href="/" className="flex items-center gap-3">
      <div
        className={`transition-all duration-300 ${
          isScrolled 
            ? 'bg-gradient-to-tr from-white/20 to-white/5 border border-white/10' 
            : 'bg-transparent'
        } p-2 rounded-xl group-hover:bg-white/10`}
      >
        <img 
          src="/onesea.png" 
          alt="ONESEA" 
          className="w-12"
        />
      </div>
      <span className="text-2xl font-bold bg-gradient-to-r from-white to-white/60 bg-clip-text text-transparent">
        ONESEA
      </span>
    </a>

              </div>

              {/* Desktop Menu */}
              <div className="hidden lg:flex items-center space-x-1">
                {MENU_ITEMS.map((item, index) => (
                  <div
                    key={index}
                    className="relative group"
                    onMouseEnter={() => item.submenu && setActiveDropdown(index)}
                    onMouseLeave={() => item.submenu && setActiveDropdown(null)}
                  >
                    <MenuItem 
                      item={item}
                      isActive={activeDropdown === index}
                      onClick={() => item.submenu && setActiveDropdown(activeDropdown === index ? null : index)}
                    />

                    {/* Dropdown Panel */}
                    {item.submenu && activeDropdown === index && (
                      <div className="absolute top-full left-1/2 -translate-x-1/2 pt-4 w-[520px]">
                        <div className="relative">
                          <div className="absolute -top-2 -left-2 -right-2 -bottom-2 
                            bg-black/40 backdrop-blur-xl rounded-2xl" />
                          
                          <div className="relative rounded-2xl border border-white/20 overflow-hidden">
                            <div className="p-6">
                              {item.submenu.map((section, sectionIndex) => (
                                <div key={sectionIndex} className="mb-6 last:mb-0">
                                  <div className="text-xs font-medium text-white/60 
                                    uppercase tracking-wider mb-4">
                                    {section.category}
                                  </div>
                                  <div className="grid gap-2">
                                    {section.items.map((subItem, subIndex) => {
                                      const SubIcon = subItem.icon;
                                      const link = subItem.link;
                                      return (
                                        <a
                                          key={subIndex}
                                          href={link}
                                    
                                          className="group flex items-center gap-4 p-3 rounded-xl hover:bg-white/10 transition-all duration-200"
                                        >
                                          <div className="shrink-0 w-10 h-10 flex items-center justify-center rounded-lg bg-gradient-to-tr from-white/20 to-white/5 border border-white/10 group-hover:border-white/20 group-hover:scale-110 transition-all duration-200">
                                            <SubIcon className="w-5 h-5 text-white" />
                                          </div>
                                          <div className="flex-1">
                                            <div className="flex items-center gap-2 mb-1">
                                              <span className="text-sm font-medium text-white">
                                                {subItem.title}
                                              </span>
                                              {subItem.badge && (
                                                <span className="px-2 py-0.5 text-xs font-medium bg-white/10 text-white/90 rounded-full">
                                                  {subItem.badge}
                                                </span>
                                              )}
                                            </div>
                                            <span className="text-sm text-white/70">
                                              {subItem.description}
                                            </span>
                                          </div>
                                          <ArrowRight className="w-4 h-4 text-white/30 opacity-0 group-hover:opacity-100 group-hover:translate-x-1 transition-all duration-200" />
                                        </a>
                                      );
                                    })}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>

              {/* Use App Button */}
              <div className="hidden lg:block">
                <a
                  href="https://forms.gle/rctyFnbXDhM3LXks7"
                  className="inline-flex items-center gap-2 px-6 py-2.5 
                    bg-white text-black rounded-xl font-medium 
                    hover:bg-white/90 transition-colors duration-200
                    active:scale-95"
                >
                  Nominate
                  <ArrowRight className="w-4 h-4" />
                </a>
              </div>

              {/* Mobile Menu Trigger */}
              <div className="lg:hidden">
                <button 
                  onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                  className="p-2 text-white/80 hover:text-white rounded-lg 
                    transition-all duration-200 hover:bg-white/10 active:scale-95"
                  aria-label="Toggle mobile menu"
                >
                  {isMobileMenuOpen ? (
                    <X className="h-6 w-6" />
                  ) : (
                    <Menu className="h-6 w-6" />
                  )}
                </button>
              </div>
            </div>
          </div>
        </nav>
      </div>

      {/* Mobile Menu */}
      <MobileMenu
        isOpen={isMobileMenuOpen}
        onClose={() => setIsMobileMenuOpen(false)}
        activeItem={activeMobileItem}
        setActiveItem={setActiveMobileItem}
      />
    </>
  );
};

export default OptimizedNav;