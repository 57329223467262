import React, { useRef, useEffect } from 'react';

const TheatricalDisplay = () => {
  const containerRef = useRef(null);
  const videoRef = useRef(null);

 

  return (
    <div 
      ref={containerRef}
      className="relative h-screen w-full bg-black overflow-hidden"
      style={{
        perspective: '1000px',
        transformStyle: 'preserve-3d'
      }}
      id="event_promo"
    >
      {/* Event Info Banner */}
      <div className="absolute top-8 left-1/2 -translate-x-1/2 z-20">
        <div className="bg-black/40 backdrop-blur-md rounded-lg p-4 text-center">
          <div className="text-gray-400 text-sm tracking-wider uppercase">Previous Event</div>
          <div className="text-white text-xl font-light mt-1">Annual Innovation Awards 2024</div>
          <div className="text-gray-300 text-sm mt-1">September 27th • Chomu Palace (Rajasthan, India) </div>
        </div>
      </div>

      <div className="relative w-full h-full" style={{ transformStyle: 'preserve-3d' }}>
        <div className="absolute inset-0 flex items-center justify-center">
          {/* Screen with Depth */}
          <div 
            ref={videoRef}
            className="relative w-3/4 aspect-video"
            style={{ 
              transformStyle: 'preserve-3d',
              transition: 'transform 0.1s ease-out',
            }}
          >
            {/* Screen Bezel */}
            <div className="absolute inset-0 -m-4 bg-gradient-to-b from-gray-800 to-gray-900 rounded-lg"
                 style={{
                   transform: 'translateZ(-20px)',
                   boxShadow: 'inset 0 0 20px rgba(0,0,0,0.5)'
                 }} />

            {/* Inner Screen Border */}
            <div className="absolute inset-0 border border-gray-700 rounded bg-black"
                 style={{
                   transform: 'translateZ(-10px)',
                   boxShadow: 'inset 0 0 30px rgba(0,0,0,0.8)'
                 }} />

            {/* Main Video Display */}
            <div className="relative w-full h-full bg-black rounded overflow-hidden"
                 style={{
                   boxShadow: '0 0 30px rgba(0,0,0,0.8)',
                   transform: 'translateZ(-5px)'
                 }}>
              <video
                className="absolute inset-0 w-full h-full object-cover"
                autoPlay
                muted
                loop
                playsInline
              >
                <source src="awards.mp4" type="video/mp4" />
              </video>

              {/* Grid Overlay */}
              <div 
                className="absolute inset-0 z-10"
                style={{
                  backgroundImage: `
                    linear-gradient(0deg, rgba(255,255,255,0.03) 1px, transparent 1px),
                    linear-gradient(90deg, rgba(255,255,255,0.03) 1px, transparent 1px)
                  `,
                  backgroundSize: 'calc(100% / 12) calc(100% / 8)',
                  pointerEvents: 'none'
                }}
              />

              {/* Screen Glass Effect */}
              <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black/10" />
            </div>

            {/* Close Reflection */}
            <div 
              className="absolute w-full"
              style={{
                top: '100%',
                height: '40%',
                transform: 'rotateX(180deg)',
                filter: 'blur(5px)',
                background: 'linear-gradient(to bottom, rgba(0,0,0,0.9), transparent 80%)',
                maskImage: 'linear-gradient(to bottom, rgba(0,0,0,1), transparent 80%)',
                WebkitMaskImage: 'linear-gradient(to bottom, rgba(0,0,0,1), transparent 80%)'
              }}
            >
              <video
                className="w-full h-full object-cover"
                autoPlay
                muted
                loop
                playsInline
              >
                <source src="awards.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
        </div>

        {/* Floor Reflection */}
        <div 
          className="absolute bottom-0 w-full h-24"
          style={{
            background: 'linear-gradient(to bottom, rgba(20,20,20,0.6), rgba(0,0,0,1))',
            transform: 'rotateX(60deg)',
            transformOrigin: 'bottom'
          }}
        />
      </div>

      {/* Ambient Lighting */}
      <div
        className="absolute inset-0 opacity-40"
        style={{
          background: 'radial-gradient(circle at 50% 50%, rgba(255,255,255,0.1), transparent 70%)',
          pointerEvents: 'none'
        }}
      />
      
      {/* Side Lighting */}
      <div className="absolute inset-0 opacity-30">
        <div className="absolute left-0 top-0 w-1/4 h-full bg-gradient-to-r from-purple-500/20 to-transparent" />
        <div className="absolute right-0 top-0 w-1/4 h-full bg-gradient-to-l from-blue-500/20 to-transparent" />
      </div>
    </div>
  );
};

export default TheatricalDisplay;