import React, { useRef } from 'react';

const TheatricalDisplay = () => {
  const containerRef = useRef(null);
  const videoRef = useRef(null);

  return (
    <div 
      ref={containerRef}
      className="relative min-h-screen w-full bg-surface-500 overflow-hidden"
      style={{
        perspective: '1000px',
        transformStyle: 'preserve-3d'
      }}
      id="previous"
    >
      {/* Main Content Container */}
      <div className="flex flex-col lg:flex-row w-full min-h-screen">
        {/* Left Video Section */}
        <div className="relative w-full lg:w-1/2 h-[50vh] lg:h-screen" style={{ transformStyle: 'preserve-3d' }}>
          <div className="absolute inset-0 flex items-center justify-center p-4 lg:p-0">
            {/* Screen with Depth */}
            <div 
              ref={videoRef}
              className="relative w-full lg:w-4/5 aspect-video"
              style={{ 
                transformStyle: 'preserve-3d',
                transition: 'transform 0.1s ease-out',
              }}
            >
              {/* Screen Bezel */}
              <div className="absolute inset-0 -m-2 lg:-m-4 bg-gradient-to-b from-surface-100 to-surface-300 rounded-lg"
                   style={{
                     transform: 'translateZ(-20px)',
                     boxShadow: 'inset 0 0 20px rgba(0,0,0,0.5)'
                   }} />

              {/* Inner Screen Border */}
              <div className="absolute inset-0 border border-surface-50 rounded bg-surface-900"
                   style={{
                     transform: 'translateZ(-10px)',
                     boxShadow: 'inset 0 0 30px rgba(0,0,0,0.8)'
                   }} />

              {/* Main Video Display */}
              <div className="relative w-full h-full bg-surface-900 rounded overflow-hidden"
                   style={{
                     boxShadow: '0 0 30px rgba(0,0,0,0.8)',
                     transform: 'translateZ(-5px)'
                   }}>
                <video
                  className="absolute inset-0 w-full h-full object-cover"
                  autoPlay
                  muted
                  loop
                  playsInline
                >
                  <source src="myra.mp4" type="video/mp4" />
                </video>

                {/* Grid Overlay */}
                <div 
                  className="absolute inset-0 z-10"
                  style={{
                    backgroundImage: `
                      linear-gradient(0deg, rgba(255,255,255,0.03) 1px, transparent 1px),
                      linear-gradient(90deg, rgba(255,255,255,0.03) 1px, transparent 1px)
                    `,
                    backgroundSize: 'calc(100% / 12) calc(100% / 8)',
                    pointerEvents: 'none'
                  }}
                />

                {/* Screen Glass Effect */}
                <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-surface-900/10" />
              </div>

              {/* Close Reflection - Hidden on mobile */}
              <div 
                className="absolute w-full hidden lg:block"
                style={{
                  top: '100%',
                  height: '40%',
                  transform: 'rotateX(180deg)',
                  filter: 'blur(5px)',
                  background: 'linear-gradient(to bottom, rgba(0,0,0,0.9), transparent 80%)',
                  maskImage: 'linear-gradient(to bottom, rgba(0,0,0,1), transparent 80%)',
                  WebkitMaskImage: 'linear-gradient(to bottom, rgba(0,0,0,1), transparent 80%)'
                }}
              >
                <video
                  className="w-full h-full object-cover"
                  autoPlay
                  muted
                  loop
                  playsInline
                >
                  <source src="myra.mp4" type="video/mp4" />
                </video>
              </div>
            </div>
          </div>

          {/* Floor Reflection - Hidden on mobile */}
          <div 
            className="absolute bottom-0 w-full h-24 hidden lg:block"
            style={{
              background: 'linear-gradient(to bottom, rgba(20,20,20,0.6), rgba(0,0,0,1))',
              transform: 'rotateX(60deg)',
              transformOrigin: 'bottom'
            }}
          />
        </div>

        {/* Right Content Section */}
        <div className="w-full lg:w-1/2 min-h-[50vh] lg:h-screen flex items-center p-6 lg:p-12">
          <div className="space-y-4 lg:space-y-6">
            <h1 className="text-3xl lg:text-4xl font-light text-text-primary">
              Business Awards 
              <span className="text-accent-primary"> 2024</span>
            </h1>
            <p className="text-text-secondary text-base lg:text-lg leading-relaxed max-w-2xl">
              The event honored groundbreaking innovation, exemplary leadership, and remarkable success across diverse industries. Extraordinary individuals were honored for their remarkable achievements. The event was a tribute to their relentless dedication, innovative spirit, and significant contributions across diverse fields. The evening was marked by a series of inspiring speeches and poignant moments of recognition, leaving everyone present deeply moved and motivated.
            </p>
          </div>
        </div>
      </div>

      {/* Ambient Lighting */}
      <div
        className="absolute inset-0 opacity-40"
        style={{
          background: 'radial-gradient(circle at 50% 50%, rgba(139,92,246,0.1), transparent 70%)',
          pointerEvents: 'none'
        }}
      />
      
      {/* Side Lighting - Adjusted for mobile */}
      <div className="absolute inset-0 opacity-30">
        <div className="absolute left-0 top-0 w-1/4 h-full bg-gradient-to-r from-purple-500/20 to-transparent" />
        <div className="absolute right-0 top-0 w-1/4 h-full bg-gradient-to-l from-purple-600/20 to-transparent" />
      </div>
    </div>
  );
};

export default TheatricalDisplay;