import React, { useEffect, useRef, useState } from 'react';
import { Shield, ChevronRight, Users, Lock, Database, Heart, HandHeart, Sparkles, CalendarCheck } from 'lucide-react';
import LogoIconSvg from '../Components/MiniLogo';

const SecurityLandingPage = () => {
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current && canvasRef.current) {
        const { width, height } = containerRef.current.getBoundingClientRect();
        const size = Math.min(width, height, 400);
        setDimensions({ width: size, height: size });
        canvasRef.current.style.width = `${size}px`;
        canvasRef.current.style.height = `${size}px`;
      }
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const dpr = window.devicePixelRatio || 1;
    canvas.width = dimensions.width * dpr;
    canvas.height = dimensions.height * dpr;
    ctx.scale(dpr, dpr);

    const centerX = dimensions.width / 2;
    const centerY = dimensions.height / 2;
    const radius = Math.min(centerX, centerY) * 0.95;

    const gridSize = 30;
    let offset = 0;

    const maxRadius = radius * 0.8;
    const rings = [
      { radius: 0, alpha: 1, active: true },
      { radius: 0, alpha: 0, active: false }
    ];

    const particles = Array.from({ length: 20 }, () => ({
      x: Math.random() * dimensions.width,
      y: Math.random() * dimensions.height,
      radius: Math.random() * 1.5 + 0.5,
      speed: Math.random() * 0.3 + 0.1,
      angle: Math.random() * Math.PI * 2
    }));

    const createCircularClip = () => {
      ctx.beginPath();
      ctx.arc(centerX, centerY, radius, 0, Math.PI * 2);
      ctx.clip();
    };

    const drawGrid = () => {
      ctx.strokeStyle = 'rgba(139, 92, 246, 0.3)';
      ctx.lineWidth = 0.5;

      for (let x = centerX - radius; x <= centerX + radius; x += gridSize) {
        ctx.beginPath();
        ctx.moveTo(x, centerY - radius);
        ctx.lineTo(x, centerY + radius);
        ctx.stroke();
      }

      for (let y = centerY - radius - offset; y <= centerY + radius; y += gridSize) {
        ctx.beginPath();
        ctx.moveTo(centerX - radius, y);
        ctx.lineTo(centerX + radius, y);
        ctx.stroke();
      }
    };

    const drawRings = () => {
      const ringWidth = 30;

      rings.forEach((ring, index) => {
        if (ring.active) {
          const gradient = ctx.createRadialGradient(
            centerX, centerY, Math.max(0, ring.radius - ringWidth),
            centerX, centerY, ring.radius + ringWidth
          );

          gradient.addColorStop(0, `rgba(139, 92, 246, 0)`);
          gradient.addColorStop(0.5, `rgba(139, 92, 246, ${0.15 * ring.alpha})`);
          gradient.addColorStop(1, `rgba(139, 92, 246, 0)`);

          ctx.fillStyle = gradient;
          ctx.beginPath();
          ctx.arc(centerX, centerY, ring.radius, 0, Math.PI * 2);
          ctx.fill();

          ring.radius += 0.3;
          if (ring.radius > maxRadius) {
            ring.radius = 0;
            ring.alpha = 0;
          }
          if (ring.radius > 0 && ring.alpha < 1) {
            ring.alpha += 0.02;
          }

          if (ring.radius > maxRadius * 0.75 && !rings[(index + 1) % 2].active) {
            rings[(index + 1) % 2].active = true;
            rings[(index + 1) % 2].radius = 0;
            rings[(index + 1) % 2].alpha = 0;
          }
        }
      });
    };

    const drawParticles = () => {
      particles.forEach(particle => {
        const dx = centerX - particle.x;
        const dy = centerY - particle.y;
        const distance = Math.sqrt(dx * dx + dy * dy);

        const repelForce = rings.reduce((force, ring) => {
          if (ring.active) {
            const distanceToRing = Math.abs(distance - ring.radius);
            if (distanceToRing < 30) {
              return Math.max(force, (30 - distanceToRing) / 10);
            }
          }
          return force;
        }, 0);

        if (repelForce > 0) {
          const angle = Math.atan2(dy, dx);
          particle.x -= Math.cos(angle) * repelForce;
          particle.y -= Math.sin(angle) * repelForce;
        } else {
          particle.x += Math.cos(particle.angle) * particle.speed;
          particle.y += Math.sin(particle.angle) * particle.speed;
        }

        const particleDistance = Math.sqrt(
          (particle.x - centerX) ** 2 + (particle.y - centerY) ** 2
        );
        if (particleDistance > radius) {
          const angle = Math.atan2(particle.y - centerY, particle.x - centerX);
          particle.x = centerX + Math.cos(angle) * radius;
          particle.y = centerY + Math.sin(angle) * radius;
        }

        ctx.beginPath();
        ctx.arc(particle.x, particle.y, particle.radius, 0, Math.PI * 2);
        ctx.fillStyle = 'rgba(139, 92, 246, 0.4)';
        ctx.fill();
      });
    };

    let animationFrameId;

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      
      ctx.save();
      createCircularClip();
      
      offset = (offset + 0.1) % gridSize;
      drawGrid();
      drawRings();
      drawParticles();
      
      ctx.restore();

      animationFrameId = requestAnimationFrame(animate);
    };

    animate();

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [dimensions]);

  const securityFeatures = [
    {
      icon: Lock,
      title: "Inspiration & Insight",
      description: "Gain valuable insights from success stories, expert discussions, and future-focused tech perspectives"
    },
    {
      icon: Shield,
      title: "Leading Innovations",
      description: "Discover groundbreaking technologies and solutions that are reshaping the future of global industries"
    },
    {
      icon: Database,
      title: "Recognize & Celebrate",
      description: "Honor exceptional achievements and innovations that drive technological advancement worldwide"
    }
  
  ];

  return (
    <div 
      className="flex bg-surface-500 relative flex-col lg:flex-row min-h-screen p-4 sm:p-6 md:p-8 lg:p-12 overflow-hidden"
      style={{
        backgroundImage: `radial-gradient(circle at top left, rgba(139, 92, 246, 0.3), rgba(139, 92, 246, 0.1) 50%, transparent 100%)`,
      }}

      id="our_process"
    >
      <div className="absolute top-0 right-0 w-1/2 h-1/2 bg-purple-500/10 blur-[120px] rounded-full animate-pulse" />
      
      <div className="flex-1 flex flex-col justify-center space-y-6 sm:space-y-8 md:space-y-10 lg:pr-8 xl:pr-16 relative z-10">
        <div className="inline-flex items-center space-x-2 bg-purple-500/10 rounded-full px-4 py-2 w-fit backdrop-blur-sm border border-purple-500/20">
          <Shield className="w-4 h-4 text-purple-400" />
          <span className="text-purple-400 text-xs sm:text-sm font-medium">
          Global Business Excellence Awards 2024
          </span>
        </div>

        <div className="inline-flex items-center space-x-2 bg-purple-500/10 rounded-full px-4 py-1 w-fit backdrop-blur-sm border border-purple-500/20">
          <CalendarCheck className="w-4 h-4 text-purple-400" />
          <span className="text-purple-400 text-xs sm:text-sm font-medium">
          100% Free Event
          </span>
        </div>

        <h1 className="text-mono-50 text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold max-w-xl leading-tight">
          <span className="bg-gradient-to-r from-purple-400 to-purple-600 bg-clip-text text-transparent">
          OUR
          </span>{' '}
           PROCESS
        </h1>


        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
          {securityFeatures.map((feature, index) => (
            <div 
              key={index}
              className="group bg-surface-400/50 backdrop-blur-xl rounded-xl p-6 border border-purple-500/20 hover:border-purple-500/40 transition-all duration-300 hover:shadow-lg hover:shadow-purple-500/10"
            >
              <div className="mb-4 p-3 rounded-xl bg-purple-500/10 group-hover:bg-purple-500/20 transition-colors duration-300 w-fit">
                <feature.icon className="w-6 h-6 text-purple-400" />
              </div>
              <h3 className="text-mono-50 font-semibold mb-2 group-hover:text-purple-400 transition-colors duration-300">
                {feature.title}
              </h3>
              <p className="text-mono-200 text-sm">
                {feature.description}
              </p>
            </div>
          ))}
        </div>

        <div className="space-y-4 sm:space-y-0 sm:space-x-4 flex flex-col sm:flex-row">
          <a
          href="https://forms.gle/rctyFnbXDhM3LXks7" 
          target="_blank" 
          rel="noopener noreferrer"
          className="inline-block">
            <button className="group relative px-6 py-3 bg-gradient-to-r from-purple-500 to-purple-600 rounded-xl text-white font-medium hover:shadow-lg hover:shadow-purple-500/25 transition-all duration-300">
              <div className="" />
              <span className="relative flex items-center justify-center">
                Secure Your Nomination
                <ChevronRight className="ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform" />
              </span>
            </button>
          </a>
         
          <a
          href="https://forms.gle/7DtdXr17fGqnWKhs7" 
          target="_blank" 
          rel="noopener noreferrer"
          className="inline-block">
          <button className="px-6 py-3 border border-purple-500/20 rounded-xl text-mono-200 hover:bg-purple-500/10 hover:text-purple-400 hover:border-purple-400/40 transition-all duration-300 flex items-center justify-center">
            <Users className="w-5 h-5 mr-2" />
            Become our Sponsor for Free
          </button>
          </a>
        </div>
      </div>

      <div ref={containerRef} className="flex-1 relative flex items-center justify-center mt-8 lg:mt-0">
        <canvas ref={canvasRef} className="absolute rounded-full" />
        <div className="relative z-10 bg-surface-500/80 backdrop-blur-xl p-8 rounded-2xl grid place-items-center border border-purple-500/20 hover:border-purple-400/40 transition-all duration-500">
          <div className="relative">
            <Sparkles className="absolute -top-4 -right-4 w-6 h-6 text-purple-400/60 animate-pulse" />
            <LogoIconSvg />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecurityLandingPage;