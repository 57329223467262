import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Trophy, Star, Award, ArrowRight, Brain, Shield, Code } from 'lucide-react';

const Spark = ({ isHorizontal, gridLines }) => {
  const [position, setPosition] = useState(0);
  const [currentLine, setCurrentLine] = useState(0);

  useEffect(() => {
    const animateSpark = () => {
      setPosition((prevPosition) => {
        if (prevPosition >= 100) {
          setCurrentLine((prevLine) => (prevLine + 1) % gridLines);
          return 0;
        }
        return prevPosition + 1;
      });
    };
    const intervalId = setInterval(animateSpark, 20);
    return () => clearInterval(intervalId);
  }, [gridLines]);

  const getLinePosition = (index) => {
    const totalLines = gridLines;
    return `${(index / (totalLines - 1)) * 100}%`;
  };

  const style = isHorizontal
    ? {
        left: `${position}%`,
        top: getLinePosition(currentLine),
        width: '30px',
        height: '2px',
      }
    : {
        top: `${position}%`,
        left: getLinePosition(currentLine),
        width: '2px',
        height: '30px',
      };

  return (
    <div
      className="absolute rounded-full"
      style={{
        ...style,
        background: isHorizontal
          ? 'linear-gradient(to right, #8b5cf6, #9d72ff, #8b5cf6)'
          : 'linear-gradient(to bottom, #8b5cf6, #9d72ff, #8b5cf6)',
        boxShadow: '0 0 5px #8b5cf6, 0 0 10px #8b5cf6',
      }}
    />
  );
};

const GlassmorphicCard = ({ category, isActive }) => {
  return (
    <motion.div
      className="relative w-full max-w-sm mx-auto"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="absolute inset-0 bg-gradient-to-br from-purple-500/30 to-purple-600/10 rounded-2xl blur-xl" />
      <div className="relative backdrop-blur-xl bg-surface-300/30 border border-purple-500/20 rounded-2xl p-6 overflow-hidden group hover:border-purple-500/40 transition-all duration-300">
        {/* Background glow effect */}
        <div className="absolute -top-4 -right-4 w-24 h-24 bg-purple-500/20 rounded-full blur-2xl group-hover:bg-purple-500/30 transition-colors duration-300" />
        
        {/* Icon container */}
        <div className="relative z-10 mb-4">
          <div className="w-12 h-12 rounded-xl bg-purple-500/20 flex items-center justify-center group-hover:bg-purple-500/30 transition-colors duration-300">
            <category.icon className="w-6 h-6 text-purple-300" />
          </div>
        </div>

        {/* Content */}
        <h3 className="text-xl font-semibold text-mono-50 mb-2 relative z-10">
          {category.title}
        </h3>
        <p className="text-mono-200 text-sm mb-4 relative z-10">
          {category.description}
        </p>

        {/* Criteria list */}
        <div className="space-y-2 relative z-10">
          {category.criteria.map((criterion, idx) => (
            <div key={idx} className="flex items-center text-sm text-mono-200">
              <div className="w-1 h-1 rounded-full bg-purple-400 mr-2" />
              {criterion}
            </div>
          ))}
        </div>

        {/* Action button */}
        {/* <button className="mt-6 w-full py-2 rounded-lg bg-purple-500/20 text-purple-300 text-sm font-medium hover:bg-purple-500/30 transition-colors duration-300 flex items-center justify-center group-hover:text-purple-200">
          Learn More
          <ArrowRight className="w-4 h-4 ml-2 group-hover:translate-x-1 transition-transform duration-300" />
        </button> */}
      </div>
    </motion.div>
  );
};

const DynamicAwardsSection = () => {
  const gridLines = 5;

  const categories = [
    {
      icon: Brain,
      title: "Global Recognition",
      description: "Gain international visibility and credibility within the global tech community.",
      criteria: [
        "Industry-wide acknowledgment",
        "Media coverage opportunities",
        "Enhanced market positioning"
      ]
    },
    {
      icon: Code,
      title: "Strategic Networking",
      description: "Connect with industry leaders, innovators, and potential partners worldwide.",
      criteria: [
        "Direct access to tech leaders",
        "Partnership opportunities", 
        "Investment connections"
      ]
    },
    {
      icon: Shield,
      title: "Business Growth",
      description: "Leverage the platform to expand your network and explore new market opportunities.",
      criteria: [
        "Market expansion potential",
        "Investor engagement",
        "Brand value enhancement"
      ]
    }
  ];

  return (
    <div className="relative min-h-screen w-full overflow-hidden bg-surface-500 py-20 px-4 sm:px-6 lg:px-8" id="benefits">
      {/* Background gradients */}
      <div 
        className="absolute inset-0"
        style={{
          backgroundImage: `
            radial-gradient(circle at 20% 20%, rgba(139, 92, 246, 0.15), transparent 40%),
            radial-gradient(circle at 80% 80%, rgba(139, 92, 246, 0.1), transparent 40%)
          `,
        }}
      />

      {/* Decorative corner elements */}
      <div className="absolute inset-x-8 top-8 flex justify-between">
        <div className="h-px w-32 bg-purple-500/20" />
        <div className="h-px w-32 bg-purple-500/20" />
      </div>
      <div className="absolute inset-x-8 bottom-8 flex justify-between">
        <div className="h-px w-32 bg-purple-500/20" />
        <div className="h-px w-32 bg-purple-500/20" />
      </div>
      <div className="absolute inset-y-8 left-8 flex flex-col justify-between">
        <div className="h-32 w-px bg-purple-500/20" />
        <div className="h-32 w-px bg-purple-500/20" />
      </div>
      <div className="absolute inset-y-8 right-8 flex flex-col justify-between">
        <div className="h-32 w-px bg-purple-500/20" />
        <div className="h-32 w-px bg-purple-500/20" />
      </div>

      {/* Grid for sparks */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 flex flex-col justify-between px-8 py-8">
          {[...Array(gridLines)].map((_, i) => (
            <div 
              key={`h-${i}`} 
              className="h-px w-full bg-purple-500/10"
              style={{
                position: 'absolute',
                top: `${(i / (gridLines - 1)) * 100}%`
              }}
            />
          ))}
        </div>
        <div className="absolute inset-0 flex justify-between px-8 py-8">
          {[...Array(gridLines)].map((_, i) => (
            <div 
              key={`v-${i}`} 
              className="h-full w-px bg-purple-500/10"
              style={{
                position: 'absolute',
                left: `${(i / (gridLines - 1)) * 100}%`
              }}
            />
          ))}
        </div>
        <Spark isHorizontal={true} gridLines={gridLines} />
        <Spark isHorizontal={false} gridLines={gridLines} />
      </div>

      {/* Main content */}
      <div className="relative z-10 max-w-7xl mx-auto">
        {/* Header */}
        <div className="text-center mb-16">
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="inline-block rounded-full bg-purple-500/10 px-4 py-2 text-sm font-medium text-purple-400 backdrop-blur-sm mb-4"
          >
            Benefits of Participation
          </motion.div>

          <motion.h1
            className="text-4xl sm:text-5xl lg:text-6xl font-bold bg-gradient-to-r from-purple-300 via-purple-400 to-purple-300 bg-clip-text text-transparent mb-6"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.2 }}
          >
            Why Attend & Nominate
          </motion.h1>

          <motion.p
            className="text-lg sm:text-xl text-mono-200 max-w-2xl mx-auto"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.3 }}
          >
            Join us to celebrate excellence, forge valuable connections, and accelerate your growth in the tech industry.
          </motion.p>
        </div>

        {/* Cards grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
          {categories.map((category, index) => (
            <GlassmorphicCard 
              key={index}
              category={category}
              isActive={false}
            />
          ))}
        </div>

        {/* Action buttons */}
        <motion.div
          className="flex flex-col sm:flex-row justify-center items-center gap-4 sm:gap-6"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.6 }}
        >
          <a
          href="https://forms.gle/rctyFnbXDhM3LXks7" 
          target="_blank" 
          rel="noopener noreferrer"
          className="inline-block">
          <button className="group px-8 py-3 bg-purple-500 rounded-xl text-white text-lg font-medium hover:bg-purple-600 transition-all duration-300 flex items-center">
            Submit Nomination
            <ArrowRight className="ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform" />
          </button>
          </a>
          
          <a
          href="https://forms.gle/7DtdXr17fGqnWKhs7" 
          target="_blank" 
          rel="noopener noreferrer"
          className="inline-block">
          <button className="group px-8 py-3 border border-purple-400 rounded-xl text-purple-400 text-lg font-medium hover:bg-purple-400/10 transition-all duration-300 flex items-center">
            Become our free sponsor
            <ArrowRight className="ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform" />
          </button>
          </a>
        </motion.div>
      </div>
    </div>
  );
};

export default DynamicAwardsSection;