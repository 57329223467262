import React, { useState, useEffect, useCallback } from 'react';
import { Award, Trophy, Star, Lightbulb, Gem, Brain, Shield, ArrowRight } from 'lucide-react';

const useMousePosition = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  
  useEffect(() => {
    const updatePosition = (e) => {
      const x = (e.clientX / window.innerWidth - 0.5) * 2;
      const y = (e.clientY / window.innerHeight - 0.5) * 2;
      setPosition({ x, y });
    };
    window.addEventListener('mousemove', updatePosition);
    return () => window.removeEventListener('mousemove', updatePosition);
  }, []);
  
  return position;
};

const useResponsiveRadius = () => {
  const [radius, setRadius] = useState(200);

  useEffect(() => {
    const updateRadius = () => {
      if (window.innerWidth >= 1280) {
        setRadius(300);
      } else if (window.innerWidth >= 1024) {
        setRadius(300);
      } else if (window.innerWidth >= 768) {
        setRadius(275);
      } else {
        setRadius(190);
      }
    };

    updateRadius();
    window.addEventListener('resize', updateRadius);
    return () => window.removeEventListener('resize', updateRadius);
  }, []);

  return radius;
};

const HighlightCube = ({ highlights, activeHighlight, setActiveHighlight }) => {
  const mousePosition = useMousePosition();
  const [rotation, setRotation] = useState(0);
  const numFaces = highlights.length;
  const radius = useResponsiveRadius();

  useEffect(() => {
    let animationFrame;
    let startTime = null;
    const duration = 30000;

    const animate = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const progress = (timestamp - startTime) % duration;
      const newRotation = (progress / duration) * 360;
      setRotation(newRotation);

      const faceDegree = 360 / numFaces;
      const activeIndex = (numFaces - Math.round(newRotation / faceDegree)) % numFaces;
      setActiveHighlight(activeIndex);

      animationFrame = requestAnimationFrame(animate);
    };

    animationFrame = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(animationFrame);
  }, [numFaces, setActiveHighlight]);

  const calculateFaceTransform = (index) => {
    const angle = (index * (360 / numFaces) + rotation) % 360;
    const rad = angle * (Math.PI / 180);
    const x = Math.sin(rad) * radius;
    const z = Math.cos(rad) * radius;
    const visibility = Math.cos(rad);

    return {
      transform: `translateX(${x}px) translateZ(${z}px) rotateY(${angle}deg)`,
      opacity: Math.max(0, visibility),
      zIndex: Math.round(visibility * 1000),
      display: visibility < -0.2 ? 'none' : 'flex',
    };
  };

  const CardFace = ({ highlight, isActive, style }) => (
    <div
      className="absolute inset-0 w-full h-full"
      style={style}
    >
      <div className={`h-full w-full overflow-hidden rounded-2xl transition-all duration-300 ${
        isActive ? 'ring-2 ring-purple-400/50' : ''
      }`}>
        <div className="h-full flex flex-col bg-gradient-to-br from-surface-300/90 to-surface-200/90 backdrop-blur-lg">
          <div className="p-6 bg-gradient-to-r from-surface-100/50 to-surface-200/50">
            <div className="flex items-center justify-between mb-4">
              <highlight.icon className={`w-10 h-10 ${
                isActive ? 'text-purple-300' : 'text-purple-500'
              }`} />
              <span className={`text-sm px-3 py-1 rounded-full ${
                isActive 
                  ? 'bg-purple-400/20 text-purple-200' 
                  : 'bg-purple-500/10 text-purple-400'
              }`}>
                {highlight.category}
              </span>
            </div>
            <h3 className={`text-xl lg:text-2xl font-bold ${
              isActive ? 'text-purple-100' : 'text-purple-300'
            }`}>
              {highlight.text}
            </h3>
          </div>

          <div className="w-full h-px bg-purple-500/20" />

          <div className="p-6 space-y-4 flex-grow">
            <p className={`text-base ${
              isActive ? 'text-mono-100' : 'text-mono-200'
            }`}>
              {highlight.description}
            </p>
            
            <div className="w-full h-px bg-purple-500/20" />
            <div className="space-y-3">
              {highlight.criteria.map((criterion, idx) => (
                <div key={idx} className="flex items-center space-x-2 text-sm text-mono-100">
                  <ArrowRight className="w-4 h-4 text-purple-400" />
                  <span>{criterion}</span>
                </div>
              ))}
            </div>
          </div>

          <div className="p-6 bg-gradient-to-t from-surface-100/50 to-transparent">
          <a
          href="https://forms.gle/7DtdXr17fGqnWKhs7" 
          target="_blank" 
          rel="noopener noreferrer"
          className="">
            <button 
              className={`w-full py-3 rounded-xl transition-all ${
                isActive 
                  ? 'bg-purple-500 hover:bg-purple-600 text-white' 
                  : 'bg-purple-500/20 hover:bg-purple-500/30 text-purple-300'
              }`}
            >
              Become our Sponsor
            </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="relative w-[320px] h-[400px] md:w-[400px] md:h-[480px] lg:w-[440px] lg:h-[520px] xl:w-[440px] xl:h-[520px] mx-auto">
      <div 
        className="absolute inset-0"
        style={{
          perspective: '2000px',
          transformStyle: 'preserve-3d',
          transform: `rotateX(${mousePosition.y * 15}deg)`,
        }}
      >
        <div
          className="relative w-full h-full"
          style={{
            transformStyle: 'preserve-3d',
            transform: `translateZ(${-radius}px)`,
          }}
        >
          {highlights.map((highlight, index) => (
            <CardFace
              key={index}
              highlight={highlight}
              isActive={index === activeHighlight}
              style={{
                ...calculateFaceTransform(index),
                transformStyle: 'preserve-3d',
                backfaceVisibility: 'hidden',
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const HeroSection = () => {
  const [activeHighlight, setActiveHighlight] = useState(0);
  const [isExploding, setIsExploding] = useState(false);

  const highlights = [
    { 
      icon: Brain, 
      category: 'AI Excellence',
      text: 'Artificial Intelligence', 
      description: 'Recognizing breakthrough innovations in AI and machine learning technologies.',
      criteria: [
        'Technical Innovation',
        'Real-world Impact',
        'Ethical Implementation',
        'Scalability'
      ]
    },
    { 
      icon: Shield, 
      category: 'Cybersecurity',
      text: 'Security Leadership', 
      description: 'Honoring excellence in cybersecurity and data protection solutions.',
      criteria: [
        'Threat Prevention',
        'Privacy Protection',
        'System Resilience',
        'Innovation'
      ]
    },
    { 
      icon: Gem, 
      category: 'Blockchain',
      text: 'Web3 Innovation', 
      description: 'Celebrating revolutionary advances in blockchain and decentralized technologies.',
      criteria: [
        'Protocol Innovation',
        'Security Design',
        'Scalability',
        'User Adoption'
      ]
    },
    { 
      icon: Star, 
      category: 'Rising Star',
      text: 'Emerging Tech', 
      description: 'Recognizing promising innovations and early-stage technological breakthroughs.',
      criteria: [
        'Innovation Potential',
        'Market Impact',
        'Technical Excellence',
        'Growth Trajectory'
      ]
    },
    { 
      icon: Trophy, 
      category: 'Leadership',
      text: 'Tech Visionary', 
      description: 'Honoring exceptional leadership and vision in technological advancement.',
      criteria: [
        'Strategic Vision',
        'Industry Impact',
        'Innovation Culture',
        'Sustainable Growth'
      ]
    },
  ];

  const handleExplode = useCallback(() => {
    setIsExploding(true);
    setTimeout(() => setIsExploding(false), 1000);
  }, []);

  return (
    <div className="pt-24 pb-24 relative min-h-screen overflow-hidden bg-gradient-to-br from-surface-500 via-surface-400 to-surface-300">
      <style jsx>{`
        @keyframes float {
          0%, 100% { transform: translateY(0); }
          50% { transform: translateY(-20px); }
        }
        @keyframes explode {
          0% { transform: scale(1); opacity: 1; }
          50% { transform: scale(1.5); opacity: 0.5; }
          100% { transform: scale(2); opacity: 0; }
        }
        @keyframes pulse {
          0%, 100% { opacity: 1; }
          50% { opacity: 0.7; }
        }
      `}</style>

      <div className="absolute inset-0 overflow-hidden">
        {[...Array(50)].map((_, i) => (
          <div
            key={i}
            className="absolute rounded-full bg-purple-400 opacity-20"
            style={{
              width: `${Math.random() * 4 + 1}px`,
              height: `${Math.random() * 4 + 1}px`,
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              animation: `float ${Math.random() * 10 + 5}s infinite ease-in-out`,
            }}
          />
        ))}
      </div>

      <div className="relative z-10 min-h-screen flex flex-col justify-center items-center px-4 md:px-8 lg:px-12 text-center">
        <h2 className="text-purple-400 text-sm font-medium tracking-[0.2em] uppercase mb-4">
          Award Categories
        </h2>
        
        <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-purple-300 via-purple-100 to-purple-200">
          Excellence Recognition
        </h1>
        
        <p className="text-lg md:text-xl lg:text-2xl mb-12 max-w-3xl text-mono-100">
          Discover our prestigious award categories celebrating innovation across the technology spectrum.
        </p>

        <div className={`mb-12 ${isExploding ? 'exploding' : ''}`}>
          <HighlightCube 
            highlights={highlights} 
            activeHighlight={activeHighlight} 
            setActiveHighlight={setActiveHighlight} 
          />
        </div>

        <a
          href="https://forms.gle/rctyFnbXDhM3LXks7" 
          target="_blank" 
          rel="noopener noreferrer"
          className="inline-block">

        <button 
          className="group px-8 py-3 bg-gradient-to-r from-purple-500 to-purple-600 rounded-xl text-lg font-medium transition-all hover:scale-105 hover:shadow-lg hover:shadow-purple-500/25 focus:outline-none focus:ring-2 focus:ring-purple-400/50"
          onClick={handleExplode}
        >
          <span className="mr-2 text-white">Submit Nomination</span>
          <ArrowRight className="inline-block w-5 h-5 text-white group-hover:translate-x-1 transition-transform" />
        </button>

        </a>
      </div>
    </div>
  );
};

export default HeroSection;