import React, { useState, useEffect } from 'react';
import { 
  Network, Users, ArrowRight, 
  HandHeart, Rocket, Globe, Sparkles, 
  CircuitBoard, Shield
} from 'lucide-react';

const MotiveSection = () => {
  const [hoveredCard, setHoveredCard] = useState(null);
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const benefits = [
    {
      icon: CircuitBoard,
      title: "Innovation Network",
      description: "Connect with pioneering tech leaders and visionaries shaping the future of innovation",
      points: ["Elite industry connections", "Strategic partnerships", "Knowledge acceleration"],
      accentGradient: "from-purple-400/20 via-purple-500/20 to-purple-600/20"
    },
    {
      icon: Shield,
      title: "Community Synergy",
      description: "Join an exclusive ecosystem of forward-thinking innovators and industry pioneers",
      points: ["Innovation masterminds", "Collaborative ventures", "Strategic insights"],
      accentGradient: "from-purple-500/20 via-purple-600/20 to-purple-700/20"
    },
    {
      icon: Rocket,
      title: "Future Acceleration",
      description: "Unlock unprecedented opportunities for global market expansion and growth",
      points: ["Strategic investments", "Market leadership", "Global scale"],
      accentGradient: "from-purple-600/20 via-purple-700/20 to-purple-800/20"
    }
  ];

  return (
    <div className="motive-section" id="motive">
      <div className="relative min-h-screen bg-surface-500 py-32 px-4 sm:px-6 lg:px-8 [&_.selection]:bg-purple-500/30 [&_.selection]:text-purple-50">
        {/* Ambient Background Effects - Scoped to this section */}
        <div className="absolute inset-0 bg-surface-500 -z-10">
          <div className="absolute inset-0 opacity-40"
               style={{
                 backgroundImage: `radial-gradient(circle at 50% ${50 + scrollY * 0.1}%, transparent 0%, rgba(139, 92, 246, 0.03) 40%, transparent 60%)`
               }} />
          <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PHBhdHRlcm4gaWQ9ImdyaWQiIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgcGF0dGVyblVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+PHBhdGggZD0iTSAwIDAgTCAyMCAwIE0gMCAwIEwgMCAyMCIgc3Ryb2tlPSIjOGI1Y2Y2IiBzdHJva2Utb3BhY2l0eT0iMC4wNSIgZmlsbD0ibm9uZSIgLz48L3BhdHRlcm4+PC9kZWZzPjxyZWN0IHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JpZCkiLz48L3N2Zz4=')] opacity-30" />
        </div>

        <div className="relative max-w-7xl mx-auto">
          {/* Header Section */}
          <div className="text-center mb-12 space-y-8">
            <div className="inline-flex items-center space-x-2 bg-purple-500/10 rounded-full px-4 py-1 w-fit backdrop-blur-sm border border-purple-500/20">
              <HandHeart className="w-4 h-4 text-purple-400" />
              <span className="text-purple-400 text-xs sm:text-sm font-medium">
                Our Motive
              </span>
            </div>

            <h2 className="text-6xl md:text-7xl font-extralight text-mono-50 tracking-tight leading-tight">
              <span className="block text-2xl md:text-3xl mt-4 text-mono-300 font-light tracking-wide">
                Why we are doing this for free?
              </span>
            </h2>

            {/* Mission Statement */}
            <div 
              className="max-w-3xl mx-auto group"
              onMouseEnter={() => setHoveredCard('mission')}
              onMouseLeave={() => setHoveredCard(null)}
            >
              <div className="relative bg-surface-400/50 backdrop-blur-2xl rounded-3xl p-12 border border-purple-500/10 transition-all duration-700">
                <div className={`absolute inset-0 bg-gradient-to-br from-purple-400/10 via-purple-500/10 to-purple-600/10 rounded-3xl opacity-0 transition-opacity duration-700 ${hoveredCard === 'mission' ? 'opacity-100' : ''}`} />
                <Globe className={`w-16 h-16 mx-auto mb-2 transition-all duration-700 ${hoveredCard === 'mission' ? 'text-purple-400 rotate-12' : 'text-mono-400'}`} />
                <p className="text-xl md:text-2xl font-light text-mono-200 leading-relaxed">
                  We provide a completely free platform to recognize and celebrate technological innovations, making global recognition accessible to all innovators regardless of their financial capacity. We believe great achievements deserve acknowledgment without financial barriers.
                </p>
              </div>
            </div>
          </div>

          <h2 className="text-center mb-8 text-6xl md:text-7xl font-extralight text-mono-50 tracking-tight leading-tight">
            <span className="block text-2xl md:text-3xl mt-4 text-mono-300 font-light tracking-wide">
              What we get?
            </span>
          </h2>

          {/* Benefits Grid */}
          <div className="grid md:grid-cols-3 gap-8">
            {benefits.map((benefit, index) => (
              <div
                key={index}
                className="group relative"
                onMouseEnter={() => setHoveredCard(index)}
                onMouseLeave={() => setHoveredCard(null)}
              >
                <div className="relative h-full bg-surface-400/50 backdrop-blur-2xl rounded-2xl p-10 border border-purple-500/10 transition-all duration-700 hover:border-purple-500/20">
                  <div className={`absolute inset-0 bg-gradient-to-br ${benefit.accentGradient} rounded-2xl opacity-0 transition-opacity duration-700 ${hoveredCard === index ? 'opacity-100' : ''}`} />
                  
                  <div className="relative z-10 h-full flex flex-col">
                    <div className={`mb-8 p-4 rounded-xl bg-surface-300/50 w-fit transition-all duration-700 ${hoveredCard === index ? 'scale-110 rotate-12' : ''}`}>
                      <benefit.icon className={`w-6 h-6 transition-colors duration-700 ${hoveredCard === index ? 'text-purple-400' : 'text-mono-400'}`} />
                    </div>
                    
                    <h3 className="text-2xl font-light text-mono-50 mb-6">
                      {benefit.title}
                    </h3>
                    
                    <p className="text-mono-300 mb-8 font-light leading-relaxed">
                      {benefit.description}
                    </p>

                    <ul className="space-y-4 mt-auto">
                      {benefit.points.map((point, idx) => (
                        <li key={idx} className="flex items-center text-mono-400 group/item">
                          <ArrowRight className={`w-4 h-4 mr-3 transition-all duration-700 ${hoveredCard === index ? 'text-purple-400 translate-x-1' : 'text-mono-500'}`} />
                          <span className="font-light transition-colors duration-700 group-hover/item:text-mono-200">
                            {point}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MotiveSection;