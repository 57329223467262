import * as THREE from 'three'
import { useEffect, useRef, useState } from 'react'
import { Canvas, useFrame, extend } from '@react-three/fiber'
import { useCursor, MeshReflectorMaterial, Image, Text, Environment, shaderMaterial } from '@react-three/drei'
import { useRoute, useLocation } from 'wouter'
import { easing } from 'maath'

const GOLDENRATIO = 1.61803398875

// Define a custom shader material for grayscale effect
const GrayscaleMaterial = shaderMaterial(
  {
    tex: null,
    zoom: 1,
  },
  // Vertex shader
  `
    varying vec2 vUv;
    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `,
  // Fragment shader
  `
    uniform sampler2D tex;
    uniform float zoom;
    varying vec2 vUv;
    
    void main() {
      vec2 zoomUv = (vUv - 0.5) / zoom + 0.5;
      vec4 color = texture2D(tex, zoomUv);
      float gray = dot(color.rgb, vec3(0.299, 0.587, 0.114));
      gl_FragColor = vec4(vec3(gray), color.a);
    }
  `
)

// Extend Three.js with our custom material
extend({ GrayscaleMaterial })

const positions = [
    // Front (Center)
    { position: [0, 0, 1.5], rotation: [0, 0, 0] },
    // Back
    { position: [-0.8, 0, -0.6], rotation: [0, 0, 0] },
    { position: [0.8, 0, -0.6], rotation: [0, 0, 0] },
    // Left
    { position: [-1.75, 0, 0.25], rotation: [0, Math.PI / 2.5, 0] },
    { position: [-2.15, 0, 1.5], rotation: [0, Math.PI / 2.5, 0] },
    { position: [-2, 0, 2.75], rotation: [0, Math.PI / 2.5, 0] },
    // Right
    { position: [1.75, 0, 0.25], rotation: [0, -Math.PI / 2.5, 0] },
    { position: [2.15, 0, 1.5], rotation: [0, -Math.PI / 2.5, 0] },
    { position: [2, 0, 2.75], rotation: [0, -Math.PI / 2.5, 0] }
]

const images = [
  {
    url: '/winner/2.png',
    name: 'Monica Rodriguez',
    title: 'Award Winner',
    year: '2024'
  },
  {
    url: '/winner/11.png',
    name: 'Eloy Perez',
    title: 'Award Winner',
    year: '2024'
  },
  {
    url: '/winner/9.png',
    name: 'Gabriel Gutierrez',
    title: 'Award Winner',
    year: '2024'
  },
  {
    url: '/winner/4.png',
    name: 'Raj Lingam',
    title: 'Award Winner',
    year: '2024'
  },
  {
    url: '/winner/13.jpg',
    name: 'Arely',
    title: 'Award Winner',
    year: '2024'
  },
  {
    url: '/winner/14.jpg',
    name: 'Alicia',
    title: 'Award Winner',
    year: '2024'
  },
  {
    url: '/winner/7.png',
    name: 'Enoc Martinez',
    title: 'Award Winner',
    year: '2024'
  },
  {
    url: '/winner/8.png',
    name: 'Enrique Matsumoto',
    title: 'Award Winner',
    year: '2024'
  },
  {
    url: '/winner/15.jpg',
    name: 'Mayra Janeth',
    title: 'Award Winner',
    year: '2024'
  },
]

function ImageDetails({ imageInfo, isVisible }) {
  if (!imageInfo) return null;

  return (
    <div className={`fixed bottom-0 left-0 right-0 z-20 transition-all duration-500 ease-in-out ${
      isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-full'
    }`}>
      <div className="bg-gradient-to-t from-black/90 to-transparent">
        <div className="max-w-[1400px] mx-auto px-8 py-12">
          <div className="flex flex-col items-center space-y-4">
            <div className="h-px w-24 bg-gradient-to-r from-transparent via-purple-500 to-transparent"></div>
            <h2 className="text-4xl font-bold text-white">{imageInfo.name}</h2>
            <div className="flex items-center space-x-4">
              <span className="text-purple-400">{imageInfo.title}</span>
              <span className="text-purple-400">•</span>
              <span className="text-purple-400">{imageInfo.year}</span>
            </div>
            <div className="h-px w-24 bg-gradient-to-r from-transparent via-purple-500 to-transparent"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Frames({ q = new THREE.Quaternion(), p = new THREE.Vector3() }) {
    const ref = useRef()
    const clicked = useRef()
    const [, params] = useRoute('/item/:id')
    const [, setLocation] = useLocation()
    const [nextIndex, setNextIndex] = useState(1)
    const [transitioning, setTransitioning] = useState(false)
    
    const [displayedImages, setDisplayedImages] = useState(() =>
      positions.map((pos, index) => ({
        ...pos,
        ...images[index],
        originalIndex: index
      }))
    )
  
    useEffect(() => {
      const intervalId = setInterval(() => {
        setTransitioning(true)
        
        setTimeout(() => {
          setDisplayedImages(prev => {
            const newImages = [...prev]
            const centerIndex = newImages.findIndex(img => 
              img.position[0] === 0 && img.position[2] === 1.5
            )
            const nextImageIndex = newImages.findIndex(img => 
              img.url === images[nextIndex].url
            )
            
            if (centerIndex !== -1 && nextImageIndex !== -1) {
              const tempPosition = [...newImages[centerIndex].position]
              const tempRotation = [...newImages[centerIndex].rotation]
              
              newImages[centerIndex].position = [...newImages[nextImageIndex].position]
              newImages[centerIndex].rotation = [...newImages[nextImageIndex].rotation]
              
              newImages[nextImageIndex].position = tempPosition
              newImages[nextImageIndex].rotation = tempRotation
            }
            
            return newImages
          })
          
          setNextIndex(current => (current + 1) % images.length)
          setTransitioning(false)
        }, 500)
      }, 10000)
  
      return () => clearInterval(intervalId)
    }, [nextIndex])
  
    useEffect(() => {
      clicked.current = ref.current.getObjectByName(params?.id)
      if (clicked.current) {
        clicked.current.parent.updateWorldMatrix(true, true)
        clicked.current.parent.localToWorld(p.set(0, GOLDENRATIO / 2, 1.25))
        clicked.current.parent.getWorldQuaternion(q)
      } else {
        p.set(0, 0, 5.5)
        q.identity()
      }
    })
  
    useFrame((state, dt) => {
      easing.damp3(state.camera.position, p, 0.4, dt)
      easing.dampQ(state.camera.quaternion, q, 0.4, dt)
    })
  
    return (
      <group
        ref={ref}
        onClick={(e) => (e.stopPropagation(), setLocation(clicked.current === e.object ? '/' : '/item/' + e.object.name))}
        onPointerMissed={() => setLocation('/')}>
        {displayedImages.map((props, index) => (
          <Frame 
            key={`${props.url}-${index}`}
            {...props}
            isAnimating={transitioning}
          />
        ))}
      </group>
    )
}

function Frame({ url, name, title, year, c = new THREE.Color(), isAnimating = false, ...props }) {
    const image = useRef()
    const frame = useRef()
    const material = useRef()
    const [texture, setTexture] = useState()
    const [, params] = useRoute('/item/:id')
    const [hovered, hover] = useState(false)
    const [rnd] = useState(() => Math.random())
    
    const frameId = url.split('').reduce((acc, char) => ((acc << 5) - acc) + char.charCodeAt(0), 0).toString(36)
    const isActive = params?.id === frameId
    
    useCursor(hovered)

    // Load texture
    useEffect(() => {
      const loader = new THREE.TextureLoader()
      loader.load(url, setTexture)
    }, [url])
  
    useFrame((state, dt) => {
      if (material.current && frame.current) {
        const baseZoom = 1.5 + Math.sin(rnd * 10000 + state.clock.elapsedTime / 3) / 2
        
        if (isAnimating) {
          const t = Math.sin(state.clock.elapsedTime * Math.PI * 2) * 0.1
          frame.current.position.y = t
          material.current.zoom = baseZoom + Math.abs(t)
        } else {
          frame.current.position.y = 0
          material.current.zoom = baseZoom
        }
  
        easing.damp3(
          image.current.scale,
          [0.85 * (!isActive && hovered ? 0.85 : 1), 0.9 * (!isActive && hovered ? 0.905 : 1), 1],
          0.1,
          dt
        )
        
        easing.dampC(
          frame.current.material.color,
          hovered ? '#000' : isAnimating ? '#000' : '#000',
          0.1,
          dt
        )
      }
    })
  
    return (
      <group {...props}>
        <mesh
          name={frameId}
          onPointerOver={(e) => (e.stopPropagation(), hover(true))}
          onPointerOut={() => hover(false)}
          scale={[1, GOLDENRATIO, 0.05]}
          position={[0, GOLDENRATIO / 2, 0]}>
          <boxGeometry />
          <meshStandardMaterial color="#1B1432" metalness={0.7} roughness={0.3} envMapIntensity={2} />
          <mesh ref={frame} raycast={() => null} scale={[0.9, 0.93, 0.9]} position={[0, 0, 0.2]}>
            <boxGeometry />
            <meshBasicMaterial toneMapped={false} fog={false} />
          </mesh>
          <mesh ref={image} raycast={() => null} position={[0, 0, 0.7]}>
            <planeGeometry args={[1, 1]} />
            <grayscaleMaterial ref={material} tex={texture} transparent />
          </mesh>
        </mesh>
      </group>
    )
}

function GalleryHeader({ isVisible }) {
  return (
    <header 
      className={`absolute top-0 left-0 right-0 z-10 bg-gradient-to-b from-black/80 to-transparent transition-all duration-500 ease-in-out ${
        isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-full pointer-events-none'
      }`}
    >
      <div className="max-w-[1400px] mx-auto px-8 pt-12 pb-20">
        <div className="flex justify-between items-center mb-12">
          <div className="h-px flex-1 bg-gradient-to-r from-transparent via-purple-500/50 to-transparent"></div>
          <div className="px-6">
            <span className="text-purple-400 tracking-[0.2em] uppercase text-sm font-medium text-white">Gallery</span>
          </div>
          <div className="h-px flex-1 bg-gradient-to-r from-transparent via-purple-500/50 to-transparent"></div>
        </div>

        <div className="text-center space-y-8">
          <div className="space-y-4">
            <h1 className="text-5xl md:text-6xl lg:text-7xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-purple-300 via-white to-purple-300 tracking-tight">
              Hall of Excellence
            </h1>
            
            <div className="flex items-center justify-center gap-3">
              <div className="w-12 h-px bg-gradient-to-r from-transparent to-purple-500"></div>
              <p className="text-xl text-white md:text-2xl text-purple-200 font-light">
                Award Winners
              </p>
              <div className="w-12 h-px bg-gradient-to-l from-transparent to-purple-500"></div>
            </div>
          </div>
        </div>

        <div className="mt-12 flex justify-center">
        </div>
      </div>
    </header>
  )
}

export default function App() {
  const [, params] = useRoute('/item/:id')
  const isHeaderVisible = !params?.id
  
  const currentImage = params?.id ? images.find(img => {
    const id = img.url.split('').reduce((acc, char) => ((acc << 5) - acc) + char.charCodeAt(0), 0).toString(36)
    return id === params.id
  }) : null

  return (
    <div className="w-full h-screen relative bg-[#191920]" id="past_winners">
      <GalleryHeader isVisible={isHeaderVisible} />
      <ImageDetails imageInfo={currentImage} isVisible={!!currentImage} />
      <Canvas dpr={[1, 1.5]} camera={{ fov: 70, position: [0, 2, 15] }}>
        <color attach="background" args={['#191920']} />
        <fog attach="fog" args={['#191920', 0, 15]} />
        <group position={[0, -0.5, 0]}>
          <Frames />
          <mesh rotation={[-Math.PI / 2, 0, 0]}>
            <planeGeometry args={[50, 50]} />
            <MeshReflectorMaterial
              blur={[300, 100]}
              resolution={2048}
              mixBlur={1}
              mixStrength={80}
              roughness={1}
              depthScale={1.2}
              minDepthThreshold={0.4}
              maxDepthThreshold={1.4}
              color="#050505"
              metalness={0.5}
            />
          </mesh>
        </group>
        <Environment preset="city" />
      </Canvas>
    </div>
  )
}